export class Constants {
  public static TYPE_AAD = "AAD";
  public static TYPE_OKTA = "OKTA";

  public static SERVER_ERROR = "Something went wrong. Please try again later";
  public static COMMUNITY_TYPE_TELLIGENT = "TELLIGENT";
  public static COMMUNITY_TYPE_FACEBOOK = "FACEBOOK";
  public static COMMUNITY_TYPE_KHOROS = "KHOROS";
  public static COMMUNITY_TYPE_KHOROS_CLASSIC = "KHOROS-CLASSIC";
  public static COMMUNITY_TYPE_MARKETO = "MARKETO";
  public static COMMUNITY_TYPE_AZURE_STORAGE = "AZURE-STORAGE";
  public static COMMUNITY_TYPE_COVEO = "COVEO";
  public static COMMUNITY_TYPE_ZENDESK = "ZENDESK";
  public static COMMUNITY_TYPE_ZENDESK_DB_SERVICE = "ZENDESK-DATABASE-SERVICE";
  public static COMMUNITY_TYPE_SHAREPOINT_STORAGE_SERVICE =
    "SHAREPOINT-STORAGE-SERVICE";
  public static COMMUNITY_TYPE_SFDC = "SFDC";
  public static COMMUNITY_TYPE_CHAMA = "CHAMA";
  public static COMMUNITY_TYPE_SMARTSHEET = "SMARTSHEET";
  public static COMMUNITY_TYPE_WEB_PROXY = "WEB-PROXY";
  public static COMMUNITY_TYPE_GOOGLE_CLOUD_STORAGE = "GOOGLE-CLOUD-STORAGE";
  public static COMMUNITY_TYPE_VERTEX_AI = "VERTEX-AI";
  public static COMMUNITY_TYPE_TEALIUM = "TEALIUM";
  public static COMMUNITY_TYPE_KHOROS_AURORA = "KHOROS-AURORA";
  public static COMMUNITY_TYPE_BIG_QUERY = "BIG-QUERY";
  public static COMMUNITY_TYPE_KHOROS_AURORA_CLASSIC = "KHOROS-AURORA-CLASSIC";
  public static COMMUNITY_TYPE_PRODUCT_BOARD = "PRODUCTBOARD";

  //Board to Board Mappings
  //public static BOARD_TO_BOARD_MAPPING_CREATED = 'Board to Board Mapping created';

  // added new community type share point
  public static COMMUNITY_TYPE_SHARE_POINT = "SHAREPOINT";
  public static NO_COMMUNITIES_FOR_CUSTOMER =
    "There are no community available for the selected customer";
  public static NO_BOARDS_FOR_CATEGORY =
    "There are no boards availables FOR the selected category";
  public static CREATE_ALL_HIDDEN_BOARDS =
    "Please create all hidden boards in community and try again";
  public static SELECT_ALL_HIDDENT_BOARDS =
    "Please select all the boards to save or update board board-mapping";
  public static SELECT_BOARD_MAPPING =
    "Please select the all details to update the mappings";
  public static DELETE_CONFIRMATION_MESSAGE =
    "Are you sure you want to delete?";
  public static TARGET_COMMUNITY_REQUIRED_MESSAGE =
    "Please add atleast one target community in order to perform community mapping";
  public static MAPPING_ALREADY_EXIST = "The Mapping is already exist";
  public static COMMUNITY_DELTED_SUCCESSFULLY =
    "Community deleted successfully";
  public static COMMUNITY_ALREADY_EXIST =
    "Community already exist with the same name";
  public static SELECT_A_CUSTOMER = "Please Select the customer";
  public static REQUEST_ACCEPTED =
    "Request accepted. Migration is under process";
  public static SELECT_ENITY_TO_MIGRATE = "Please select an entity to migrate";
  public static ROLE_INVALID_CONTACT_SUPERADMIN =
    "Thank you for your interest on Data Syndication. Please contact ";
  public static OAUTH_AUTHORIZE_URL = "auth/oauth2/authorize?client_id";
  public static OAUTH_REDIRECT_URI = "response_type=code&redirect_uri";
  public static RESET_PASSWORD_AAD =
    "Please contact AAD Admin to reset the password";
  public static RESET_PASSWORD_OKTA =
    "Please check your email to reset password";
  public static EMAIL_NOT_EXIST = "The email you entered is not exist";
  public static LOG_OUT_FROM_MICROSOFT =
    "Please wait...login out from the provider";
  public static BOARDS_CREATED_SUCCESSFULLY = "Boards are saved successfully";
  public static BOARDS_UPDATED_SUCCESSFULLY = "Boards are updated successfully";
  public static UNABLE_TO_GET_TARGET_BOARDS = "Unable to get target boards";
  public static UNABLE_TO_GET_TARGET_CATEGORIES =
    "Unable to get target categories";
  public static MAX_CUSTOM_FIELS_TO_BE_15_ONLY =
    "Maximum custom fields to be 15 only. you can not add more than" +
    " 15 custom fields.";
  public static MAX_FIELDS_AUTO_SYNDICATION =
    "All Target Communities have been selected";
  public static CATEGORY_ID_REQUIRED = "Category ID is required";
  public static SELECT_ATLEAST_ONE_MAPPING =
    "Please select atleast one mapping related to autosyndication";
  public static SELECT_TARGET_COMMUNITY =
    "Please Select all the fields related to autosyndication";
  public static DUPLICATE_ENTITIES_FOUND =
    "Duplicate entities found for same target community, Please remove duplicate entities and try again";
  public static ENTER_BOARD_LABELS = "Please Enter board labels";
  public static TELLIGENT_SUPPORTED_BOARDS =
    "Only Forum and Blog board types are supported for Telligent Community type. Please select Forum or Blog source board";

  public static ENABLE_COMMUNITIES_COMMUNITY_MAPPINGS =
    ". Please enable community(s) and create applications mapping(s)" +
    " for data syndication.";
  public static VALID_INPUT_FOR_COMMUNITY_VALIDATION =
    "Please enter a valid Access Details";
  public static INVALID_BULK_INPUT =
    "Pleae enter values for bulk data community ID, bulk data client ID and  bulk API" +
    " access token";
  public static CONTACT_SUPER_ADMIN =
    '<a href="mailto:support@italentdigital.com">Super Admin</a>';
  public static GOOGLE_CLOUD_LOGOUT_URL = "https://accounts.google.com/logout";
  public static NO_RESULTS_FOUND =
    "No results are found in the source community";
  public static CUSTOMER_EXISTS = "Customer with given name already exists.";
  public static DOMAIN_EXISTS = "Domain already exists.";
  public static DOMAIN_DUPLICATE = "Domains cannot be repeated.";
  public static SESSION_EXPIRED = "Session Expired, Please login again";
  public static FILE_EXTENSION_SUPPORTED = "Please Upload csv file";
  public static MtlsFILE_EXTENSION_SUPPORTED =
    "Please Upload .pem and .key file";
  public static GCPFILE_EXTENSION_SUPPORTED = "Please Upload .json file";
  public static FILE_UPLOADED_SUCCESSFULLY = "File Uploaded successfully";
  public static SELECT_FIELDS =
    "Please select customer, community and upload file";
  public static INVALID_CUSTOMER_CREDENTIALS =
    "Please enter valid client id, client secret or tenant id";
  public static CUSTOMER_DETAILS_VERIFIED_SUCCESSFULLY =
    "Customer Details verified successfully, please save the customer.";
  public static FAILED_TO_AUTHORIZE_FB_USER =
    "Failed to Authorize for Application ";
  public static FB_AUTHENTICATION_SUCCESS =
    "Successfully Authenticated for Application ";
  /**
   * Connectors
   */
  public static SELECT_SOURCE_TARGET_FIELDS =
    "Please select source and target fields";
  public static SELECT_SOURCE_TARGET_COMMUNITIES =
    "Please select source and target communitiy";
  public static SELECT_ATLEAST_ONE_FIELD_MAPPING =
    "Please select atleast one field mapping";
  public static CONNECTOR_MAPPING_SAVED_SUCCESSFULLY =
    "Connector Mapping Saved Successfully";
  public static FIELD_MAPPINGS_SAVED_SUCCESSFULLY =
    "Field mappings saved successfully";
  public static AUTOSYNDICATION_MAPPINGS_SAVED_SUCCESSFULLY =
    "Autosyndication mappings saved successfully";
  public static AUTOSYNDICATION_MAPPINGS_DELETED_SUCCESSFULLY =
    "Autosyndication mappings deleted successfully";
  public static DUPLICATE_ENTRIES_FOUND =
    "Duplicate Entries found, please remove duplicate entries and save.";
  public static SCHEDULE_CONFIGURED = "Schedule Configured Successfully";
  public static REPROCESS_CONFIGURED = "Re-Process Configured Successfully";

  public static ENTITY_TYPE_EXISTS = "Selected Entity Type already exists";

  public static SELECT_SOURCE_BOARD = "Select Source Board";
  public static SELECT_TARGET_BOARD = "Select Target Board";
  public static SELECT_TARGET_CATEGORY = "Select Target Category";
  /**
   * Schedulers
   */
  public static FROM_DATE_BEFORE_TO_DATE = "From Date should be before To Date";
  public static FROM_DATE_BEFORE_CURRENT_DATE =
    "From Date should be before current Date";
  public static TO_DATE_BEFORE_CURRENT_DATE =
    "To Date should be before current Date";
  public static RE_BUILD_ALREADY_EXISTS =
    "Schedule Already exists in given time interval";
  public static SCHEDULE_CONFIG_TIME = "Please Configure Schedule Time";
  /**
  /**
   * Hard coding the language translation provider
   */
  public static TRANSLATION_PROVIDER =
    '[{"name":"google","value":"Google Translation"},' +
    '{"name":"lingotek","value":"Lingotek Translation"},' +
    '{"name":"bing","value":"Microsoft Text translation"},' +
    '{"name":"systran","value":"Systran Text translation"},' +
    '{"name":"amazon_translator", "value":"Amazon Translation"},' +
    '{"name":"smartling", "value":"SmartLing Translation"},' +
    '{"name":"autodeskmachine", "value":"Autodesk Machine Translation"},' +
    '{"name":"deepl","value":"DeepL Text translation"}]';

  public static INTERACTION_TYPE =
    '[{"name":"tkb","value":"TKB"},' +
    '{"name":"blog","value":"Blogs"},' +
    '{"name":"occasion","value":"Occasion"},' +
    '{"name":"forum","value":"Forum"},' +
    '{"name":"idea","value":"Ideas"}]';

  public static TRANSLATION_AUTHTYPE =
    '[{"name":"oauth2", "value":"OAuth2"},' +
    '{"name":"apikey","value":"API Key"}]';
  /**
   * Health check service
   */

  public static CRONJOB_STARTED =
    "Cron job for Customer AAD Token Expiration started successfully";
  public static CRONJOB_STOPPED =
    "Cron job for Customer AAD Token Expiration stopped successfully";
  public static CRONJOB_STATUS =
    "Unable to retrieve valid Cron job status of Customer AAD Token Expiration";

  /**
   * Data migration file upload
   */
  public static COMMUNITIES_EMPTY_ERROR_MSG =
    "Please wait untill load both source and target communities";
  public static FILE_EMPTY_ERROR_MSG = "Please select atleast one CSV file";

  /**Translation Tab */
  public static TRANSLATION_VERIFIED =
    "Translation details verified successfully. Please save the details.";
  public static INVALID_TRANSLATION_DETAILS =
    "Invalid credentials. Please enter valid details.";
}
