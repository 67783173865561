import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ModalComponent } from '../../../shared/components/modal/pages/modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectorService } from '../../../core/services/connector/connector.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { error } from '@angular/compiler/src/util';
import { Constants } from 'src/app/shared/constants/constants';
import { SkilljarEvent } from 'src/app/shared/models/skilljar-event.model';
import { MatTooltipModule } from '@angular/material/tooltip';
export interface ScheduleStatus {
    scheduleStatus: any;
    selectedConnector: any;
    entityType: any;
    skillJarEventsList: any;
    totalSchedulesCount:number;
}

@Component({
    selector: 'schedule-status.component',
    templateUrl: 'schedule-status.component.html',
    styleUrls: ['./schedule-status.component.css']
})
export class ScheduleStatusDialog implements OnInit, AfterViewInit {
    @ViewChild(ModalComponent, { static: true }) modalComponent;
    @ViewChild('viewSchedulesPaginator',  {read: MatPaginator, static: false}) viewSchedulesPaginator: MatPaginator;
    @ViewChild('viewSchedulesSort', {static: false}) viewSchedulesSort: MatSort;
    dataSource: MatTableDataSource<any>;
    selectedConnector: any;
    skillJarEventsList: SkilljarEvent[];
    entityType: any;
    spinner = false;
    config = new MatSnackBarConfig();
    scheduleListColumns = [];
    pageSize = 25;
    total: number;
    currentPage = 0;
    activeSort: String = "lastUpdatedTime";
    sortOrder: string = "desc";
    
    

    constructor(
        public dialogRef: MatDialogRef<ScheduleStatusDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ScheduleStatus, private connectorService: ConnectorService, private snackBar: MatSnackBar) {
          if (data.entityType === 'skill-jar-events') {
            this.scheduleListColumns = [
              'eventDate',
              'userEmail',
              'courseTitle',
              'message',
              'status',
              'retryButton'
            ];
            this.skillJarEventsList = data.skillJarEventsList;
            this.entityType = data.entityType;
            this.dataSource = new MatTableDataSource(data.skillJarEventsList);
            this.dataSource.sortingDataAccessor = (item, property) => {
              if (property.includes('.')) return property.split('.').reduce((o,i)=>o[i], item);
              return item[property];
            };
            this.dataSource.sort = this.viewSchedulesSort;
          }
       else{
        this.scheduleListColumns=['scheduledFrom', 'completedAt', 'type', 'sourceStatus', 'targetStatus', 'scheduleStatus', 'totalCount', 'successCount', 'droppedCount','failedCount', 'retrySchedule'];
            this.selectedConnector = data.selectedConnector;
            this.entityType=data.entityType;

            this.total = data.totalSchedulesCount;
            this.dataSource = new MatTableDataSource(data.scheduleStatus);
            this.dataSource.paginator = this.viewSchedulesPaginator;          
            setTimeout(() => {
              if (this.viewSchedulesPaginator) {
                this.viewSchedulesPaginator.length = this.total;
                this.viewSchedulesPaginator.pageIndex = this.currentPage;
                this.viewSchedulesPaginator.pageSize = this.pageSize;
              }
            });
            this.spinner = false;
          }
          
    }

    ngOnInit() {
        this.config.verticalPosition = 'top';
        this.config.horizontalPosition = 'end';
        this.config.duration = 5000;
        this.config.panelClass = ['blue-snackbar'];
    }

    ngAfterViewInit() {
      if (this.viewSchedulesPaginator && this.dataSource) {
        this.dataSource.paginator = this.viewSchedulesPaginator;
      }
      if (this.viewSchedulesSort && this.dataSource) {
        this.dataSource.sort = this.viewSchedulesSort;
      }
    }
    
    close(): void {
        this.dialogRef.close();
    }

  reloadData(pageSize,currentPage,activeSort,sortOrder) {
    this.spinner = true;
    this.connectorService.getSchedules(
      this.selectedConnector.id,pageSize||25,             
      currentPage||0,         
      activeSort||"lastUpdatedTime",     
      sortOrder||"desc"            
    ).subscribe(res => {
      this.total = res.response.totalRecordsCount;
      this.dataSource = new MatTableDataSource(res.response.data);
      this.dataSource.paginator = this.viewSchedulesPaginator;
      if (typeof this.viewSchedulesPaginator.length === "undefined") {
        this.viewSchedulesPaginator.length = currentPage;
      }
    
      setTimeout(() => {
        this.viewSchedulesPaginator.length = this.total;
        this.viewSchedulesPaginator.pageIndex = currentPage;
        this.viewSchedulesPaginator.pageSize = pageSize;
      });
      this.spinner = false;
    }, error => {
      this.spinner = false;
      return this.snackBar.open(error.error.response.message, 'Close', this.config);
    });
  }

  onChangePage(event) {
    if (this.pageSize !== event.pageSize) {
      this.currentPage = 0; 
      this.pageSize = event.pageSize;
    } else {
      this.currentPage = event.pageIndex;
    }
    this.pageSize = +event.pageSize;
    this.reloadData(this.pageSize,this.currentPage,this.activeSort,this.sortOrder);
  }
  onSortChange(event) {
    this.sortOrder =
      event.direction == ""
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : this.sortOrder == event.direction
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : event.direction;
    this.reloadData(
      this.pageSize,
      0,
      event.active,
      this.sortOrder
    );
  }
    retrySchedule(row: any) {
      this.connectorService.retryFailedSchedules(row.id)
        .subscribe(res => {
          console.log(res);
          if (res.response.code === 200 && res.response.status === 'success') {
            this.dialogRef.close(res.response.status);
            return this.snackBar.open(Constants.SCHEDULE_CONFIGURED, 'Close', this.config);
        }
        }, error => {
          console.log("Error occured when retring the schedule");
      });
    }
    reprocessSkillJarEvents(row: SkilljarEvent) {
      this.connectorService.reprocessSkillJarEvents(row).subscribe(
          res => {
              if (res.response.code === 200 && res.response.status === 'success') {
                  this.dialogRef.close(res.response.status);
                  return this.snackBar.open(Constants.SCHEDULE_CONFIGURED, 'Close', this.config);
              }
          },
          error => {
              this.snackBar.open('Error occurred while retrying SkillJar Events', 'Close', this.config);
          }
      );
  }
}
