import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalComponent } from "../../shared/components/modal/pages/modal.component";
import { ClientsService } from "../../core/services/customer/clients.service";
import { CommunityService } from "../../core/services/community/community.service";
import { ConnectorService } from "../../core/services/connector/connector.service";
import { MappingsService } from "../../core/services/Mappings/mappings.service";
import { Customer } from "../../shared/models/customer";
import { Community } from "../../shared/models/community";
import { CustomAttributes } from "../../shared/models/custom-attributes";
import { Constants } from "../../shared/constants/constants";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../../shared/confirmationDialog/confirmationdialog.component";
import { ScheduleConfigDialog } from "../connector/pages/schedule-config.component";
import { ScheduleStatusDialog } from "../connector/schedule-status/schedule-status.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { forkJoin } from "rxjs";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { CoveoCmsEntityMapping } from "src/app/shared/models/coveo_cms_entity_mappings";
import { ElementRef } from "@angular/core";
import { SkilljarEvent } from "src/app/shared/models/skilljar-event.model";

@Component({
  selector: "app-connector",
  templateUrl: "./connector.component.html",
  styleUrls: ["./connector.component.css"],
})
export class ConnectorComponent implements OnInit {
  @ViewChild("csvImportBoard") csvImportBoard;
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  @ViewChild("fileInput", { static: false })
  fileInputRef: ElementRef;

  @ViewChild("connectorMapping", { static: true })
  connectorMapping: MatExpansionPanel;

  @ViewChild("viewConnectorMappingsPaginator", {
    read: MatPaginator,
    static: true,
  })
  viewConnectorMappingsPaginator: MatPaginator;
  @ViewChild("viewConnectorMappingsSort", { static: true })
  viewConnectorMappingsSort: MatSort;
  connectorMappingListColumns = [
    "customer.customerName",
    "entityType",
    "sourceCommunity.communityName",
    "targetCommunity.communityName",
    "schedule",
    "action",
  ];
  fieldMappingEntityTypes = [
    { name: 'Message', value: 'message' },
    { name: 'User', value: 'user' },
    { name: 'Kudos', value: 'kudos' },
    { name: 'Badges', value: 'badges' }
  ]
  connectorMappingListDataSource: MatTableDataSource<any>;
  ConnectorForm: FormGroup;
  config = new MatSnackBarConfig();
  sourceCommunities: Community[] = [];
  targetCommunities: Community[] = [];
  entities: any[] = [];
  entityTypeOptions: any[] = [];

  communities: Community[];
  communityCustomFields: any[] = [];
  communityProductBoardFields: any[] = [];
  connectorMappings: any[] = [];
  filteredConnectorMappingsList: any[] = [];
  schedularConfig: any[] = [];
  fieldMappingList: any[] = [];
  productBoardMappingList: any[] = [];
  spinner = false;
  index: any;
  schedularData: any;
  customer: any;
  customers: Customer[] = [];
  customAttributes: CustomAttributes[] = [];
  sourceCustomAttributes: CustomAttributes[] = [];
  selectedFieldMappingEntityType: any = "";
  sourceCommunity: any = "";
  targetCommunity: any = "";
  targetCommunityType: any = "";
  selectedConnector: any;
  targetCommunityObj: Community;
  panelOpenState = false;
  name: any;
  customerId: any = 0;
  selectedSourceCommunity: Community;
  selectedTargetCommunity: Community;
  khorosAllCommunity: any;
  customerObj = JSON.parse(localStorage.getItem("customerObj") || "{}");
  superAdmin = localStorage.getItem("isSuperAdmin") === "yes" ? true : false;
  communityBoardsList: any[] = [];
  categoriesList: any;
  grouphubsList: any[] = [];
  selectedCommunityBoards: any[] = [];
  selectedCategories: any[] = [];
  selectedGrouphubs: any[] = [];
  coveoCmsEntityMappingsList: CoveoCmsEntityMapping[] = [];
  isUpdate: boolean = false;
  http: any;
  searchString: string = "";
  dialogRef: any;
  pageSize = 50;
  total: number;
  activeSort: string = "customer.customerId";
  sortOrder: string = "asc";
  currentPage = 0;

  selectedFile: File;

  objects: any = [];
  selectedObject: any;
  createScheduleObj: any;



  constructor(
    private formBuilder: FormBuilder,
    private customerService: ClientsService,
    private communityService: CommunityService,
    private mappingService: MappingsService,
    private connectorService: ConnectorService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.buildConnectorForm();
  }

  sourceField: string; // Declare the sourceField property
  targetField: string; // Declare the targetField property
  i: number; // Declare the i property
  entityType: string;
  // selectedEntityType: string = "";
  syndicateSolutionMessage: boolean = false;
  messageCreate: boolean = false;
  messageUpdate: boolean = false;
  messageDelete: boolean = false;
  solutionUpdate: boolean = false;
  moderationApproved: boolean = false;
  spam: boolean = false;
  ham: boolean = false;

  resetUsers(data: any, confirm: boolean): void {
    if (confirm) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "376px",
        height: "197px",
        data: {
          title: "Reset-Users",
          message: "Are you sure you want to Reset-Users ?",
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.spinner = true;
          const isReset = true;
          const connectorId = data.id;

          this.connectorService.resetUsers(connectorId).subscribe(
            (res) => {
              this.spinner = false;
              if (
                res.response.code === 200 &&
                res.response.status === "success"
              ) {
                return this.snackBar.open(
                  Constants.SCHEDULE_CONFIGURED,
                  "Close",
                  this.config
                );
                this.dialogRef.close();
              }
            },
            (error) => {
              this.spinner = false;
              if (
                error.status === 401 &&
                error.error.response.status === "error" &&
                error.error.response.message ===
                "UnAuthorized Access Token Expired"
              ) {
                return this.snackBar.open(
                  Constants.SESSION_EXPIRED,
                  "Close",
                  this.config
                );
              } else if (error.error.message.includes("409")) {
                return this.snackBar.open(
                  Constants.RE_BUILD_ALREADY_EXISTS,
                  "Close",
                  this.config
                );
              } else {
                return this.snackBar.open(
                  error.error.response.message,
                  "Close",
                  this.config
                );
              }
            }
          );
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  // updateEntityTypeOptions(
  //   sourceCommunityName: string,
  //   targetCommunityName: string
  // ) {
  //   console.log("selected", sourceCommunityName, targetCommunityName);

  //   if (
  //     sourceCommunityName === "English" &&
  //     targetCommunityName === "SFDC-Dev1"
  //   ) {
  //     this.entityTypeOptions = [
  //       {
  //         name: "message",
  //         id: "messageMapping",
  //       },
  //       {
  //         name: "User ",
  //         id: "userMapping",
  //       },
  //     ];
  //   } else {
  //     this.entityTypeOptions = [
  //       {
  //         name: "Bulk Data",
  //         id: "bulkdata",
  //       },
  //       {
  //         name: "Khoros Events Pull Mechanism",
  //         id: "khorosEventsPullMechanism",
  //       },
  //       {
  //         name: "Translation",
  //         id: "translation",
  //       },
  //     ];
  //   }
  // }

  ngOnInit() {
    this.config.verticalPosition = "top";
    this.config.horizontalPosition = "end";
    this.config.duration = 5000;
    this.config.panelClass = ["blue-snackbar"];
    this.fetchConnectorMappings();
    this.entities = [
      {
        name: "Messages",
        id: "messages",
      },
      {
        name: "Users",
        id: "users",
      },
      {
        name: "Bulk Data",
        id: "bulkdata",
      },
      {
        name: "Khoros Events Pull Mechanism",
        id: "khorosEventsPullMechanism",
      },
      {
        name: "Khoros Views & Ratings Pull Mechanism",
        id: "khoros-views-ratings-pull-mechanism",
      },
      {
        name: "SFDC Events Pull Mechanism",
        id: "sfdc-events-pull-mechanism",
      },
      {
        name: "Smartling Events Pull Mechanism TKB",
        id: "smartling-events-pull-mechanism-tkb",
      },
      {
        name: "Smartling Events Pull Mechanism BLOG",
        id: "smartling-events-pull-mechanism-blog",
      },
      {
        name: "Smartling Events Pull Mechanism FORUM",
        id: "smartling-events-pull-mechanism-forum",
      },
      {
        name: "Smartling Events Pull Mechanism IDEA",
        id: "smartling-events-pull-mechanism-idea",
      },
      {
        name: "Data Migration",
        id: "data-migration",
      },
      {
        name: "Newly Registered Users",
        id: "newly-registered-users",
      },
      {
        name: "User Cleanup",
        id: "userCleanup",
      },
      {
        name: "Data Migration Users",
        id: "data-migration-users",
      },
      {
        name: "Data Migration Messages",
        id: "data-migration-messages",
      },
      {
        name: "SkillJar Events",
        id: "skill-jar-events",
      },
      {
        name: "Vocher",
        id: "vocher",
      },
    ];

    this.objects = [
      {
        name: "Users",
        id: "Users",
      },
      {
        name: "Messages",
        id: "Messages",
      },
    ];
  }

  fetchCustomers(pageSize, currentPage, sortName, sortOrder, searchString) {
    if (searchString == "") {
      this.spinner = true;
    }
    forkJoin(
      this.customerService.getActiveCustomers(
        localStorage.getItem("loginUserId") || "{}"
      ),
      this.connectorService.getConnectorMappings(
        this.customerId,
        pageSize,
        currentPage,
        sortName,
        sortOrder,
        searchString
      )
    ).subscribe(
      ([customers, mappings]: any) => {
        this.spinner = false;
        if (this.customers.length == 0) {
          this.customers = customers.response.data;
        }
        this.connectorMappings = mappings.response.data;
        this.connectorMappingListDataSource = new MatTableDataSource(
          this.connectorMappings
        );
        this.total = mappings.response.totalRecordsCount;
        setTimeout(() => {
          if (this.viewConnectorMappingsPaginator != undefined) {
            this.viewConnectorMappingsPaginator.length = this.total;
            this.viewConnectorMappingsPaginator.pageIndex = currentPage;
            this.viewConnectorMappingsPaginator.pageSize = pageSize;
          }
        });
      },
      (error) => {
        this.spinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response && error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  onChangePage(event) {
    if (this.pageSize !== event.pageSize) {
      this.currentPage = 0; // Reset to first page if page size changes
    } else {
      this.currentPage = event.pageIndex;
    }
    this.pageSize = +event.pageSize;
    if (this.superAdmin) {
      this.fetchCustomers(
        this.pageSize,
        this.currentPage,
        this.activeSort,
        this.sortOrder,
        this.searchString
      );
    } else {
      this.customer = {
        customerId: this.customerObj.id,
        customerName: this.customerObj.name,
      };
      this.customerId = this.customerObj.id;
      this.fetchCommunities(this.customerObj.id,false);
    }
  }

  fetchCommunities(selectedCustomer: string,isEdit: boolean) {
    if (selectedCustomer !== "") {
      this.spinner = true;
      forkJoin(
        this.mappingService.getCommunitybyCustomerId(+selectedCustomer),
        this.connectorService.getConnectorMappings(
          selectedCustomer,
          this.pageSize,
          this.currentPage,
          this.activeSort,
          this.sortOrder,
          this.searchString
        )
      ).subscribe(
        ([communities, mappings]: any) => {
          this.communities = communities.response.data;
          this.connectorMappings = mappings.response.data;
          if (!isEdit) {
          this.connectorMappingListDataSource = new MatTableDataSource(
            this.connectorMappings
          );
        }

          this.connectorMappingListDataSource.paginator =
            this.viewConnectorMappingsPaginator;
          this.connectorMappingListDataSource.sortingDataAccessor = (
            item,
            property
          ) => {
            if (property.includes("."))
              return property.split(".").reduce((o, i) => o[i], item);
            return item[property];
          };
          this.connectorMappingListDataSource.sort =
            this.viewConnectorMappingsSort;
          this.communities.map((obj) => {
            if (
              obj.communityFunction === "SOURCE" ||
              obj.communityFunction === "BOTH"
            ) {
              this.sourceCommunities.push(obj);
              this.targetCommunities.push(obj);
            } else {
              this.targetCommunities.push(obj);
            }
          });
          this.spinner = false;
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else {
      this.communities = [];
      this.setDefault();
    }
  }

  fetchCommunitiesOnCustomerChange(event: any) {
    const selectedCustomer = event.target.value;
    this.setDefault();
    this.fetchCommunities(selectedCustomer,false);
  }

  configSchedule(scheduleType: any, data: any, isReset: boolean) {
    if (scheduleType === "incremental") {
      this.spinner = true;
      this.connectorService.getSchedulesByType(scheduleType, data.id).subscribe(
        (res) => {
          this.spinner = false;
          if (res.length > 0) {
            this.schedularData = res[0];
          } else {
            this.schedularData = undefined;
          }
          const dialogRef = this.dialog.open(ScheduleConfigDialog, {
            minWidth: "650px",
            minHeight: "400px",
            data: {
              data: data,
              type: scheduleType,
              schedularData: this.schedularData,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === "success") {
              this.fetchConnectorMappings();
            }
          });
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else if (scheduleType === "rescanroles") {
      this.spinner = true;
      this.connectorService.getSchedulesByType(scheduleType, data.id).subscribe(
        (res) => {
          this.spinner = false;
          if (res.length > 0) {
            this.schedularData = res[0];
          } else {
            this.schedularData = undefined;
          }
          const dialogRef = this.dialog.open(ScheduleConfigDialog, {
            minWidth: "650px",
            minHeight: "400px",
            data: {
              data: data,
              type: scheduleType,
              schedularData: this.schedularData,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === "success") {
              this.fetchConnectorMappings();
            }
          });
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else if (scheduleType === "rescanbulkdata") {
      this.spinner = true;
      this.connectorService.getSchedulesByType(scheduleType, data.id).subscribe(
        (res) => {
          this.spinner = false;
          if (res.length > 0) {
            this.schedularData = res[0];
          } else {
            this.schedularData = undefined;
          }
          const dialogRef = this.dialog.open(ScheduleConfigDialog, {
            minWidth: "650px",
            minHeight: "400px",
            data: {
              data: data,
              type: scheduleType,
              schedularData: this.schedularData,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined && result === "success") {
              this.fetchConnectorMappings();
            }
          });
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else if (scheduleType === "re-build") {
      const dialogRef = this.dialog.open(ScheduleConfigDialog, {
        width: "650px",
        height: "400px",
        data: {
          data: data,
          type: scheduleType,
          schedularData: this.schedularData,
          isReset: isReset,
        },
      });
      dialogRef.afterClosed().subscribe((result) => { });
    } else if (scheduleType === "re-process") {
      this.spinner = true;
      this.connectorService.configReprocess(data.id).subscribe(
        (res) => {
          this.spinner = false;
          if (res.response.code === 200 && res.response.status === "success") {
            return this.snackBar.open(
              Constants.REPROCESS_CONFIGURED,
              "Close",
              this.config
            );
          }
          return;
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.message,
              "Close",
              this.config
            );
          }
        }
      );
    } else {
      const dialogRef = this.dialog.open(ScheduleConfigDialog, {
        width: "900px",
        height: "400px",
        data: {
          data: data,
          type: scheduleType,
          schedularData: this.schedularData,
        },
      });

      dialogRef.afterClosed().subscribe((result) => { });
    }
  }
  onSourceCommunityChange(event: any) {
    const selectedCommunity = event.target.value;
    if (selectedCommunity !== undefined && selectedCommunity !== "") {
      this.selectedSourceCommunity = this.sourceCommunities.filter(
        (data) => data.communityId === parseInt(selectedCommunity)
      )[0];
      this.getCustomAttributes();
    }
  }

  public confirm(fieldMappingData: any, index: any) {
    this.index = index;
    fieldMappingData.data.splice(index, 1);
  }

  public confirmDeleteFieldMapping(index: any) {
    this.index = index;
    this.communityCustomFields.splice(index, 1);
  }

  // Confirm delete product board mapping
  public confirmDeleteProductBoardMapping(index: number) {
      this.index = index;
      this.communityProductBoardFields.splice(index, 1);
  }

  onTargetCommunityChange(event: any) {
    const selectedCommunity = event.target.value;
    this.targetCommunityType = this.targetCommunities.filter(
      (obj) => obj.communityId.toString() === selectedCommunity
    )[0].communityType.communityTypeName;
    if (selectedCommunity !== undefined && selectedCommunity !== "") {
      this.selectedTargetCommunity = this.targetCommunities.filter(
        (data) => data.communityId === parseInt(selectedCommunity)
      )[0];
      if (
        this.targetCommunityType === Constants.COMMUNITY_TYPE_COVEO ||
        this.targetCommunityType === Constants.COMMUNITY_TYPE_WEB_PROXY ||
        this.targetCommunityType ===
        Constants.COMMUNITY_TYPE_GOOGLE_CLOUD_STORAGE ||
        this.targetCommunityType === Constants.COMMUNITY_TYPE_VERTEX_AI
      ) {
        this.getCustomAttributes();
        this.getCategoryListBySourceCommunity(
          this.selectedSourceCommunity.communityId
        );
        this.getCommunityBoards();
      }
    }
  }

  onSortChange(event) {
    this.sortOrder =
      event.direction == ""
        ? this.sortOrder == "asc"
          ? "desc"
          : "asc"
        : this.sortOrder == event.direction
          ? this.sortOrder == "asc"
            ? "desc"
            : "asc"
          : event.direction;
    if (this.superAdmin) {
      this.fetchCustomers(
        this.pageSize,
        this.currentPage,
        event.active,
        this.sortOrder,
        this.searchString
      );
    } else {
      this.customer = {
        customerId: this.customerObj.id,
        customerName: this.customerObj.name,
      };
      this.customerId = this.customerObj.id;
      this.fetchCommunities(this.customerObj.id,false);
    }
  }
  changedCoveoMessagesEnabled(eleRef: any) {
    if (!eleRef.target.checked) {
      this.selectedCategories = [];
      this.selectedGrouphubs = [];
      this.selectedCommunityBoards = [];
      this.ConnectorForm.controls["groupHubsEnabled"].setValue("");
      this.ConnectorForm.controls["allGroupHubsEnabled"].setValue("");
      this.ConnectorForm.controls["allEventsOutSideGroupHubsEnabled"].setValue(
        ""
      );
      this.ConnectorForm.controls["khorosAllCommunity"].setValue("true");
    }
  }
  getCommunityBoards() {
    // if(this.selectedCommunityBoards.length == 0 && !this.isUpdate){
    //   this.selectedCommunityBoards.push({
    //     boardId:'',
    //     boardTitle:'',
    //     boardList:[]
    //   })
    // }
    this.getBoardsListBySourceCategoryId("community", 0, "community");
  }
  addCommunityBoardsList() {
    this.selectedCommunityBoards.push({
      boardId: "",
      boardTitle: "",
      boardList: [],
    });
  }
  addCategoriesList() {
    this.selectedCategories.push({
      categoryId: "",
      boardList: [],
    });
  }
  addGroupHubList() {
    this.selectedGrouphubs.push({
      groupHubId: "",
      groupHubTitle: "",
      boardList: [],
    });
  }
  getGroupHubs(isChecked: any) {
    this.spinner = true;
    // if(this.selectedGrouphubs.length == 0 && !this.isUpdate){
    //   this.selectedGrouphubs.push({
    //     groupHubId:'',
    //     groupHubTitle:'',
    //     boardList:[]
    //   })
    // }
    console.log("gettting grouphubs details:isChecked::" + isChecked);
    if (isChecked == true) {
      this.mappingService
        .getGroupHubsByCommunityId(this.selectedSourceCommunity.communityId)
        .subscribe((res) => {
          this.grouphubsList = res.response.data;
          this.spinner = false;
        });
    }
  }

  selectedAllGroupHubsEnabled(event: any) {
    const isChecked = event.target && event.target.checked;
    if (isChecked == true) {
      this.selectedGrouphubs = [];
    }
  }

  getCategoryListBySourceCommunity(communityId: number) {
    this.spinner = true;
    // if(this.selectedCategories.length == 0 && !this.isUpdate){
    //   this.selectedCategories.push({
    //     categoryId:'',
    //     categoryTitle:'',
    //     boardList:[]
    //   })
    // }
    this.mappingService
      .getCategoriesByCommunity(communityId)
      .subscribe((res) => {
        this.categoriesList = res.response.data;
        this.spinner = false;
      });
  }
  changedBoard(event: any, position: number, nodeType: string) {
    const boardId = event.target.value;
    if (nodeType == "category") {
      this.selectedCategories[position].boardId = boardId;
      this.selectedCategories[position].boardList.map((boardDetails: any) => {
        if (boardDetails.id == boardId) {
          this.selectedCategories[position].boardTitle = boardDetails.title;
          this.selectedCategories[position].boardStyle =
            boardDetails.conversation_style;
        }
      });
    } else if (nodeType == "grouphub") {
      this.selectedGrouphubs[position].boardId = boardId;
      this.selectedGrouphubs[position].boardList.map((boardDetails: any) => {
        if (boardDetails.id == boardId) {
          this.selectedGrouphubs[position].boardTitle = boardDetails.title;
          this.selectedGrouphubs[position].boardStyle =
            boardDetails.conversation_style;
        }
      });
    } else {
      this.selectedCommunityBoards[position].boardId = boardId;
      this.communityBoardsList.map((boardDetails) => {
        if (boardDetails.id == boardId) {
          this.selectedCommunityBoards[position].boardTitle =
            boardDetails.title;
          this.selectedCommunityBoards[position].boardStyle =
            boardDetails.conversation_style;
        }
      });
    }
  }
  async getBoardsListBySourceCategoryId(
    event: any,
    position: number,
    nodeType: string
  ) {
    const nodeId = event;
    this.spinner = true;
    if (nodeType == "category") {
      this.selectedCategories[position].categoryId = nodeId;
      let catDetails = "";
      if (this.categoriesList !== undefined && this.categoriesList.length > 0) {
        catDetails = this.categoriesList.filter(
          (cat: any) => cat.categoryId == nodeId
        );
      }
      if (catDetails.length > 0) {
        this.selectedCategories[position] = Object.assign(
          this.selectedCategories[position],
          catDetails[0]
        );
      }

      this.mappingService
        .getBoardsByCategoryId(
          this.selectedSourceCommunity.communityId,
          nodeId,
          "all",
          nodeType
        )
        .subscribe((res) => {
          this.selectedCategories[position].boardList = res.response.data;
          this.spinner = false;
        });
    } else if (nodeType == "grouphub") {
      let ghDetails = this.grouphubsList.filter(
        (gh) => gh.groupHubId == nodeId
      );
      if (ghDetails.length > 0) {
        this.selectedGrouphubs[position] = Object.assign(
          this.selectedGrouphubs[position],
          ghDetails[0]
        );
      }
      this.mappingService
        .getBoardsByCategoryId(
          this.selectedSourceCommunity.communityId,
          nodeId,
          "all",
          nodeType
        )
        .subscribe((res) => {
          this.selectedGrouphubs[position].boardList = res.response.data;
          this.spinner = false;
        });
    } else {
      this.mappingService
        .getBoardsByCategoryId(
          this.selectedSourceCommunity.communityId,
          nodeId,
          "all",
          nodeType
        )
        .subscribe((res) => {
          this.communityBoardsList = res.response.data;
          this.spinner = false;
        });
    }
  }

  deleteCoveoCmsEntityMapping(position: number, nodeType: string) {
    if (nodeType === "category") {
      this.selectedCategories = this.selectedCategories.filter(
        (cont, index) => index != position
      );
    } else if (nodeType === "grouphub") {
      this.selectedGrouphubs = this.selectedGrouphubs.filter(
        (cont, index) => index != position
      );
    } else {
      this.selectedCommunityBoards = this.selectedCommunityBoards.filter(
        (cont, index) => index != position
      );
    }
  }
  entityName(id: any): any {
    if (id !== undefined && id !== null) {
      return this.entities.filter((data) => data.id === id).length > 0
        ? this.entities.filter((data) => data.id === id)[0].name
        : "";
    } else {
      return "";
    }
  }
  getCustomAttributes() {
    //this.spinner = true;
    if (this.selectedConnector !== undefined) {
      this.spinner = true;
        // Retrieve field mappings and product-board mappings independently
        forkJoin({
          fieldMappings: this.connectorService.getFieldMappings(this.selectedConnector.id),
          productBoardMappings: this.connectorService.getProductBoardMappings(this.selectedConnector.id)
        }).subscribe(
          (responses) => {
            // Process both responses
            this.communityCustomFields = responses.fieldMappings;
            this.processAndFilterFieldMappings(this.communityCustomFields);
            this.communityProductBoardFields = responses.productBoardMappings;
            this.processAndFilterProductBoardMappings(this.communityProductBoardFields);
            this.spinner = false;
          },
          (error) => {
            this.spinner = false;
            return this.snackBar.open(
              "Error getting Field Mappings",
              "Close",
              this.config
            );
          }
        );
    }
  }

  reset() {
    this.connectorMapping.close();
    this.sourceCommunity = "";
    this.targetCommunity = "";
    this.ConnectorForm.reset();
    this.ConnectorForm.controls["customer"].setValue("");
    this.ConnectorForm.controls["entityType"].setValue("");
    this.selectedSourceCommunity;
    this.selectedTargetCommunity;
    this.selectedConnector = undefined;
    this.customAttributes = [];
    this.communityCustomFields = [];
    this.communityProductBoardFields = [];
    this.fieldMappingList = [];
    this.productBoardMappingList = [];
    this.schedularConfig = [];
    this.sourceCommunities = [];
    this.targetCommunities = [];
    this.selectedCategories = [];
    this.selectedGrouphubs = [];
    this.selectedCommunityBoards = [];
    this.ConnectorForm.controls["groupHubsEnabled"].setValue("");
    this.ConnectorForm.controls["allGroupHubsEnabled"].setValue("");
    this.ConnectorForm.controls["allEventsOutSideGroupHubsEnabled"].setValue(
      ""
    );
    this.ConnectorForm.controls["excludedRoles"].setValue("");
    this.ConnectorForm.controls["communicationEmail"].setValue("");
    this.ConnectorForm.controls["excludeEmailDomains"].setValue("");
    this.ConnectorForm.controls["khorosAllCommunity"].setValue("true");
    this.ConnectorForm.controls["coveoMessagesEnabled"].setValue("");
    this.ConnectorForm.controls["privateMessagesEnabled"].setValue("");
    this.ConnectorForm.controls["publicMessages"].setValue("");
  }

  edit(connector: any) {
    this.spinner = true;
    this.isUpdate = true;
    this.selectedCategories = [];
    this.selectedGrouphubs = [];
    this.selectedCommunityBoards = [];
    this.syndicateSolutionMessage = connector.syndicateSolutionMessage;
    this.messageCreate = connector.bulkDataActionKeys.messageCreate;
    this.messageUpdate = connector.bulkDataActionKeys.messageUpdate;
    this.messageDelete = connector.bulkDataActionKeys.messageDelete;
    this.solutionUpdate = connector.bulkDataActionKeys.solutionUpdate;
    this.spam = connector.bulkDataActionKeys.spam;
    this.ham = connector.bulkDataActionKeys.ham;
    this.moderationApproved = connector.bulkDataActionKeys.moderationApproved;
    this.currentPage=0;
    this.fetchCommunities(connector.customer.customerId,true);
    this.selectedConnector = connector;
    this.selectedSourceCommunity = connector.sourceCommunity;
    this.selectedTargetCommunity = connector.targetCommunity;
    this.selectedSourceCommunity;
    this.ConnectorForm.controls["customer"].setValue(
      connector.customer.customerId
    );
    if (connector.entityType === undefined || connector.entityType === null) {
      this.ConnectorForm.controls["entityType"].setValue("");
    } else {
      this.ConnectorForm.controls["entityType"].setValue(connector.entityType);
    }
    this.sourceCommunity = connector.sourceCommunity.communityId.toString();
    this.targetCommunity = connector.targetCommunity.communityId.toString();
    this.targetCommunityType =
      this.selectedTargetCommunity.communityType.communityTypeName;
    this.getCustomAttributes();

    if (
      this.targetCommunityType === "COVEO" ||
      this.targetCommunityType === "WEB-PROXY" ||
      this.targetCommunityType === "GOOGLE-CLOUD-STORAGE" ||
      this.targetCommunityType === "VERTEX-AI"
    ) {
      this.getCategoryListBySourceCommunity(
        connector.sourceCommunity.communityId
      );
      this.getGroupHubs(connector.groupHubsEnabled);
      this.coveoCmsEntityMappingsList = connector.coveoCMSEntityMappings;
      this.getCommunityBoards();

      this.coveoCmsEntityMappingsList.map(async (coveoCmsEntityMapping) => {
        let obj: any = {};
        if (
          (coveoCmsEntityMapping.nodeType == "category" &&
            coveoCmsEntityMapping.nodeType !== "grouphub") ||
          (coveoCmsEntityMapping.parentNodeType == "category" &&
            coveoCmsEntityMapping.nodeType !== "grouphub")
        ) {
          if (coveoCmsEntityMapping.nodeType == "board") {
            obj.boardId = coveoCmsEntityMapping.nodeId;
            obj.boardTitle = coveoCmsEntityMapping.nodeTitle;
            obj.categoryId = coveoCmsEntityMapping.parentNodeId;
            obj.categoryTitle = coveoCmsEntityMapping.parentNodeTitle;
            obj.parentCategoryId = coveoCmsEntityMapping.rootNodeId;
            obj.parentCategoryTitle = coveoCmsEntityMapping.rootNodeTitle;
            obj.rootCategoryId = coveoCmsEntityMapping.rootNodeId;
            obj.rootCategoryTitle = coveoCmsEntityMapping.rootNodeTitle;
            obj.boardList = [];
          } else {
            obj.boardId = "";
            obj.boardTitle = "";
            obj.categoryId = coveoCmsEntityMapping.nodeId;
            obj.categoryTitle = coveoCmsEntityMapping.nodeTitle;
            obj.parentCategoryId = coveoCmsEntityMapping.parentNodeId;
            obj.parentCategoryTitle = coveoCmsEntityMapping.parentNodeTitle;
            obj.rootCategoryId = coveoCmsEntityMapping.rootNodeId;
            obj.rootCategoryTitle = coveoCmsEntityMapping.rootNodeTitle;
            obj.boardList = [];
          }
          this.selectedCategories.push(obj);
          let num = this.selectedCategories.length - 1;
          this.getBoardsListBySourceCategoryId(obj.categoryId, num, "category");
        } else if (
          coveoCmsEntityMapping.nodeType == "grouphub" ||
          coveoCmsEntityMapping.parentNodeType == "grouphub"
        ) {
          if (coveoCmsEntityMapping.nodeType == "board") {
            obj.boardId = coveoCmsEntityMapping.nodeId;
            obj.boardTitle = coveoCmsEntityMapping.nodeTitle;
            obj.groupHubId = coveoCmsEntityMapping.parentNodeId;
            obj.groupHubTitle = coveoCmsEntityMapping.parentNodeTitle;
            obj.parentId = coveoCmsEntityMapping.rootNodeId;
            obj.parentTitle = coveoCmsEntityMapping.rootNodeTitle;
            obj.parentType = coveoCmsEntityMapping.parentNodeType;
            obj.rootCategoryId = coveoCmsEntityMapping.rootNodeId;
            obj.rootCategoryTitle = coveoCmsEntityMapping.rootNodeTitle;
            obj.boardList = [];
          } else {
            obj.boardId = "";
            obj.boardTitle = "";
            obj.groupHubId = coveoCmsEntityMapping.nodeId;
            obj.groupHubTitle = coveoCmsEntityMapping.nodeTitle;
            obj.parentId = coveoCmsEntityMapping.parentNodeId;
            obj.parentTitle = coveoCmsEntityMapping.parentNodeTitle;
            obj.parentType = coveoCmsEntityMapping.parentNodeType;
            obj.rootCategoryId = coveoCmsEntityMapping.rootNodeId;
            obj.rootCategoryTitle = coveoCmsEntityMapping.rootNodeTitle;
            obj.boardList = [];
          }
          this.selectedGrouphubs.push(obj);
          let num = this.selectedGrouphubs.length - 1;
          await this.getBoardsListBySourceCategoryId(
            obj.groupHubId,
            num,
            "grouphub"
          );
        } else {
          obj.boardId = coveoCmsEntityMapping.nodeId;
          obj.boardTitle = coveoCmsEntityMapping.nodeTitle;
          obj.parentId = coveoCmsEntityMapping.parentNodeId;
          obj.parentTitle = coveoCmsEntityMapping.parentNodeTitle;
          obj.parentType = coveoCmsEntityMapping.parentNodeType;
          obj.rootCategoryId = coveoCmsEntityMapping.rootNodeId;
          obj.rootCategoryTitle = coveoCmsEntityMapping.rootNodeTitle;
          obj.boardList = [];
          this.selectedCommunityBoards.push(obj);
        }
      });
    }
    if (connector.entityType == "userCleanup") {
      this.ConnectorForm.controls["excludedRoles"].setValue(
        connector.khorosConnectorDetails.excludedRoles
      );
      this.ConnectorForm.controls["communicationEmail"].setValue(
        connector.khorosConnectorDetails.communicationEmail
      );
      this.ConnectorForm.controls["excludeEmailDomains"].setValue(
        connector.khorosConnectorDetails.excludeEmailDomains
      );
    }
    this.ConnectorForm.controls["coveoMessagesEnabled"].setValue(
      connector.coveoMessagesEnabled
    );
    this.ConnectorForm.controls["privateMessagesEnabled"].setValue(
      connector.privateMessagesEnabled
    );
    this.ConnectorForm.controls["khorosAllCommunity"].setValue(
      connector.khorosAllCommunity
    );
    this.ConnectorForm.controls["publicMessages"].setValue(
      connector.publicMessages
    );
    this.ConnectorForm.controls["groupHubsEnabled"].setValue(
      connector.groupHubsEnabled
    );
    this.ConnectorForm.controls["allGroupHubsEnabled"].setValue(
      connector.allGroupHubsEnabled
    );
    this.ConnectorForm.controls["allEventsOutSideGroupHubsEnabled"].setValue(
      connector.allEventsOutSideGroupHubsEnabled
    );
    this.selectedAllGroupHubsEnabled(connector.allGroupHubsEnabled);
    this.spinner = false;
    this.connectorMapping.open();
    // this.updateEntityTypeOptions(
    //   connector.sourceCommunity.communityName,
    //   connector.targetCommunity.communityName
    // );
    this.entityType = connector.entityType;
    // console.log("entity eytpe ", this.entityType);
    // console.log(connector);

    // if (this.entityType === 'userMapping' ){
    // }
  }

  schedular(connector: any) {
    this.selectedConnector = connector;
    this.sourceCommunity = connector.sourceCommunity.communityId.toString();
    this.targetCommunity = connector.targetCommunity.communityId.toString();
    if (connector.entityType == "skill-jar-events") {
      this.spinner = true;
      this.connectorService.getUnprocessedSkilljarEvents(connector.id).subscribe(
        (res: SkilljarEvent[]) => {
          const dialogRef = this.dialog.open(ScheduleStatusDialog, {
            minWidth: "750px",
            minHeight: "500px",
            data: {
              skillJarEventsList: res,
              entityType: connector.entityType,
            },
          });
          dialogRef.afterClosed().subscribe((result) => { });
          this.spinner = false;
        }
      )

    }
    else {
      this.spinner = true;
      this.connectorService.getSchedules(connector.id,25,0,"lastUpdatedTime","desc").subscribe(
        (res) => {
          this.schedularConfig = res.response.data;
          const dialogRef = this.dialog.open(ScheduleStatusDialog, {
            minWidth: "750px",
            minHeight: "500px",
            data: {
              scheduleStatus: this.schedularConfig,
              selectedConnector: this.selectedConnector,
              entityType: connector.entityType,
              totalSchedulesCount:res.response.totalRecordsCount,
            },
          });
          dialogRef.afterClosed().subscribe((result) => { });
          this.spinner = false;
        }
        ,
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
    }
  }

  delete(connector: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "450px",
      height: "200px",
      data: {
        message:
          "Do you confirm the deletion of the Connector Mapping " +
          connector.sourceCommunity.communityName +
          " -> " +
          connector.targetCommunity.communityName +
          "?",
        type: "",
        title: "Confirm Action",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner = true;
        this.connectorService.deleteConnectorMapping(connector.id).subscribe(
          (res) => {
            if (res.response.code === 200) {
              this.spinner = false;
              this.getConnectorMappings();
              return this.snackBar.open(
                res.response.message,
                "Close",
                this.config
              );
            }
            return;
          },
          (error) => {
            this.spinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
              "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
      }
    });
  }

  public add(fieldMappingData: any) {
    this.targetCommunityObj = this.targetCommunities.filter(
      (obj) => obj.communityId === parseInt(this.targetCommunity)
    )[0];
    const customFieldsLength = fieldMappingData.data.length;
    if (customFieldsLength > 0) {
      let customAttribute = fieldMappingData.data[customFieldsLength - 1];

      if (
        customAttribute.targetFieldName === "" ||
        customAttribute.sourceFieldName === ""
      ) {
        return this.snackBar.open(
          Constants.SELECT_SOURCE_TARGET_FIELDS,
          "Close",
          this.config
        );
      }
    }
    fieldMappingData.data.push({
      id: 0,
      targetFieldName: "",
      targetFieldType: "",
      sourceFieldName: "",
      sourceCommunityId: 0,
      targetCommunityId: 0,
      entityType: fieldMappingData.key
    });
  }

  public addFieldMapping() {
    this.targetCommunityObj = this.targetCommunities.filter(
      (obj) => obj.communityId === parseInt(this.targetCommunity)
    )[0];
    const customFieldsLength = this.communityCustomFields.length;
    if (customFieldsLength > 0) {
      let customAttribute = this.communityCustomFields[customFieldsLength - 1];

      if (
        customAttribute.targetFieldName === "" ||
        customAttribute.sourceFieldName === ""
      ) {
        return this.snackBar.open(
          Constants.SELECT_SOURCE_TARGET_FIELDS,
          "Close",
          this.config
        );
      }
    }
    this.communityCustomFields.push({
      id: 0,
      targetFieldName: "",
      targetFieldType: "",
      sourceFieldName: "",
      sourceCommunityId: 0,
      targetCommunityId: 0,
    });
  }

  public addProductBoardMapping() {
    this.targetCommunityObj = this.targetCommunities.filter(
      (obj) => obj.communityId === parseInt(this.targetCommunity)
    )[0];
    const customFieldsLength = this.communityProductBoardFields.length;
    if (customFieldsLength > 0) {
      let customAttribute = this.communityProductBoardFields[customFieldsLength - 1];

      if (
        customAttribute.targetFieldName === "" ||
        customAttribute.sourceFieldName === ""
      ) {
        return this.snackBar.open(
          Constants.SELECT_SOURCE_TARGET_FIELDS,
          "Close",
          this.config
        );
      }
    }
    this.communityProductBoardFields.push({
      id: 0,
      targetFieldName: "",
      sourceFieldName: "",
      sourceCommunityId: 0,
      targetCommunityId: 0,
    });
  }

  setDefault() {
    this.sourceCommunity = "";
    this.targetCommunity = "";
    this.selectedSourceCommunity;
    this.selectedTargetCommunity;
    this.selectedConnector = undefined;
    this.sourceCommunities = [];
    this.targetCommunities = [];
    this.customAttributes = [];
    this.communityCustomFields = [];
    this.schedularConfig = [];
    this.ConnectorForm.controls["khorosAllCommunity"].setValue("true");
  }

  buildConnectorForm() {
    this.ConnectorForm = this.formBuilder.group({
      source_community_id: new FormControl(""),
      target_community_id: new FormControl(""),
      customer: new FormControl(""),
      entityType: new FormControl(""),
      communityCoveoConfiguration: new FormControl(""),
      coveoMessagesEnabled: new FormControl(""),
      privateMessagesEnabled: new FormControl(""),
      khorosPullMechanismEnabled: new FormControl(""),
      khorosAllCommunity: new FormControl(""),
      publicMessages: new FormControl(""),
      excludedRoles: new FormControl(""),
      excludeEmailDomains: new FormControl(""),
      communicationEmail: new FormControl(""),
      groupHubsEnabled: new FormControl(""),
      allGroupHubsEnabled: new FormControl(""),
      allEventsOutSideGroupHubsEnabled: new FormControl(""),
      importFile: new FormControl("", []),
      objectType: new FormControl(""),
      messagesOrUserIds: new FormControl(""),
    });
  }

  getFieldMappings() {
    if (this.sourceCommunity !== "" && this.targetCommunity !== "") {
      this.spinner = true;
      this.connectorService
        .getFieldMappings(this.selectedConnector.id)
        .subscribe(
          (res) => {
            this.communityCustomFields = res;
            this.processAndFilterFieldMappings(this.communityCustomFields);
            this.spinner = false;
          },
          (error) => {
            this.spinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
              "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
    }
  }

  getProductBoardMappings() {
    if (this.sourceCommunity !== "" && this.targetCommunity !== "") {
      this.spinner = true;
      this.connectorService
        .getProductBoardMappings(this.selectedConnector.id)
        .subscribe(
          (res) => {
            this.communityProductBoardFields = res;
            this.processAndFilterProductBoardMappings(this.communityProductBoardFields);
            this.spinner = false;
          },
          (error) => {
            this.spinner = false;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
              "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }
          }
        );
    }
  }

  onPanelOpened(fieldMapping: any) {
    if (this.selectedConnector || (this.selectedSourceCommunity && this.selectedTargetCommunity)) {
      this.spinner = true;
      let sourceCommunityId;
      let targetCommunityId;
      if (this.selectedConnector) {
        sourceCommunityId = this.selectedConnector.sourceCommunity.communityId;
        targetCommunityId = this.selectedConnector.targetCommunity.communityId;
      } else if (this.selectedSourceCommunity) {
        sourceCommunityId = this.selectedSourceCommunity.communityId;
      }
      if (!targetCommunityId && this.selectedTargetCommunity) {
        targetCommunityId = this.selectedTargetCommunity.communityId;
      }
      this.connectorService
        .getCustomFieldsByCommunityId(sourceCommunityId, fieldMapping.key)
        .subscribe(
          (customAttributes) => {
            let filteredList;
            if (this.entityType === 'users') {
              this.sourceCustomAttributes = customAttributes;
            } else {
              filteredList = this.fieldMappingList.filter(d => d.key === fieldMapping.key);
              if (filteredList) {
                filteredList[0].sourceFields = customAttributes;
              }
            }
            this.connectorService
              .getCustomFieldsByCommunityId(targetCommunityId, fieldMapping.key)
              .subscribe(
                (targetAttributes) => {
                  if (this.entityType === 'users') {
                    this.customAttributes = targetAttributes;
                  } else {
                    if (filteredList) {
                      filteredList[0].targetFields = targetAttributes;
                    }
                  }
                  this.spinner = false;
                },
                (error) => {
                  this.spinner = false;
                  return this.snackBar.open(
                    "Error getting Field Mappings",
                    "Close",
                    this.config
                  );
                }
              );
          }, (error) => {
            this.spinner = false;
            return this.snackBar.open(
              "Error getting Field Mappings",
              "Close",
              this.config
            );
          }

        );
    }
  }

  getConnectorMappings() {
    this.spinner = true;
    this.connectorService
      .getConnectorMappings(
        this.customerId,
        this.pageSize,
        this.currentPage,
        this.activeSort,
        this.sortOrder,
        this.searchString
      )
      .subscribe(
        (res) => {
          this.connectorMappings = res;
          this.connectorMappingListDataSource = new MatTableDataSource(
            this.connectorMappings
          );

          this.connectorMappingListDataSource.paginator =
            this.viewConnectorMappingsPaginator;
          this.connectorMappingListDataSource.sortingDataAccessor = (
            item,
            property
          ) => {
            if (property.includes("."))
              return property.split(".").reduce((o, i) => o[i], item);
            return item[property];
          };
          this.connectorMappingListDataSource.sort =
            this.viewConnectorMappingsSort;
          this.spinner = false;
        },
        (error) => {
          this.spinner = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            return this.snackBar.open(
              Constants.SESSION_EXPIRED,
              "Close",
              this.config
            );
          } else {
            return this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
        }
      );
  }

  submit() {
    if (this.sourceCommunity === "" || this.targetCommunity === "") {
      return this.snackBar.open(
        Constants.SELECT_SOURCE_TARGET_COMMUNITIES,
        "Close",
        this.config
      );
    }
    let fieldMappings = [];
    if (this.entityType === 'users') {
      fieldMappings = this.communityCustomFields;
    } else {
      this.communityCustomFields = [];
      for (let fieldMappingData of this.fieldMappingList) {
        if (fieldMappingData.data) {
          this.communityCustomFields = this.communityCustomFields.concat(fieldMappingData.data);
        }
      }
    }
    for (let dataObj of this.communityCustomFields) {
      if (dataObj.targetFieldName === "" || dataObj.sourceFieldName === "") {
        return this.snackBar.open(
          Constants.SELECT_SOURCE_TARGET_FIELDS,
          "Close",
          this.config
        );
      }
      let k = this.communityCustomFields.filter(
        (obj) =>
          obj.targetFieldName === dataObj.targetFieldName &&
          obj.targetFieldType === dataObj.targetFieldType &&
          obj.sourceFieldName === dataObj.sourceFieldName &&
          obj.entityType === dataObj.entityType
      ).length;
      if (k > 1) {
        return this.snackBar.open(
          Constants.DUPLICATE_ENTRIES_FOUND,
          "Close",
          this.config
        );
      }
    }

    let customer;
    let customerId = this.ConnectorForm.controls["customer"].value;
    if (customerId !== "" && this.customers.length > 0) {
      customer = this.customers.filter(
        (data) => parseInt(data.customerId.toString()) === parseInt(customerId)
      )[0];
    } else {
      customer = this.customer;
    }
    let entityType =
      this.ConnectorForm.controls["entityType"] === undefined
        ? null
        : this.ConnectorForm.controls["entityType"].value;
    let communicationEmail =
      this.ConnectorForm.controls["communicationEmail"] === undefined
        ? null
        : this.ConnectorForm.controls["communicationEmail"].value;
    let excludedRoles =
      this.ConnectorForm.controls["excludedRoles"] === undefined
        ? null
        : this.ConnectorForm.controls["excludedRoles"].value;
    let excludeEmailDomains =
      this.ConnectorForm.controls["excludeEmailDomains"] === undefined
        ? null
        : this.ConnectorForm.controls["excludeEmailDomains"].value;
    let coveoCmsEntityMappingList: CoveoCmsEntityMapping[] = [];
    this.selectedCategories.map((selectedCategory) => {
      let coveoCmsEntityMapping: any = {};
      if (
        selectedCategory.boardId !== "" &&
        selectedCategory.boardId != undefined &&
        selectedCategory.boardId != null
      ) {
        coveoCmsEntityMapping.nodeId = selectedCategory.boardId;
        coveoCmsEntityMapping.nodeTitle = selectedCategory.boardTitle;
        coveoCmsEntityMapping.nodeType = "board";
        coveoCmsEntityMapping.interactionStyle = selectedCategory.boardStyle;
        coveoCmsEntityMapping.parentNodeId = selectedCategory.categoryId;
        coveoCmsEntityMapping.parentNodeTitle = selectedCategory.categoryTitle;
        coveoCmsEntityMapping.parentNodeType = "category";
        coveoCmsEntityMapping.rootNodeId =
          selectedCategory.rootCategoryId == ""
            ? selectedCategory.parentCategoryId == ""
              ? selectedCategory.categoryId
              : selectedCategory.parentCategoryId
            : selectedCategory.rootCategoryId;
        coveoCmsEntityMapping.rootNodeTitle =
          selectedCategory.rootCategoryTitle == ""
            ? selectedCategory.parentCategoryTitle == ""
              ? selectedCategory.categoryTitle
              : selectedCategory.parentCategoryTitle
            : selectedCategory.rootCategoryTitle;
        coveoCmsEntityMapping.rootNodeType = "category";
      } else {
        coveoCmsEntityMapping.nodeId = selectedCategory.categoryId;
        coveoCmsEntityMapping.nodeTitle = selectedCategory.categoryTitle;
        coveoCmsEntityMapping.nodeType = "category";
        coveoCmsEntityMapping.interactionStyle = "";
        coveoCmsEntityMapping.parentNodeId =
          selectedCategory.parentCategoryId == ""
            ? selectedCategory.rootCategoryId == ""
              ? selectedCategory.categoryId
              : selectedCategory.rootCategoryId
            : selectedCategory.parentCategoryId;
        coveoCmsEntityMapping.parentNodeTitle =
          selectedCategory.parentCategoryTitle == ""
            ? selectedCategory.rootCategoryTitle == ""
              ? selectedCategory.categoryTitle
              : selectedCategory.rootCategoryTitle
            : selectedCategory.parentCategoryTitle;
        coveoCmsEntityMapping.parentNodeType = "category";
        coveoCmsEntityMapping.rootNodeId =
          selectedCategory.rootCategoryId == ""
            ? selectedCategory.parentCategoryId == ""
              ? selectedCategory.categoryId
              : selectedCategory.parentCategoryId
            : selectedCategory.rootCategoryId;
        coveoCmsEntityMapping.rootNodeTitle =
          selectedCategory.rootCategoryTitle == ""
            ? selectedCategory.parentCategoryTitle == ""
              ? selectedCategory.categoryTitle
              : selectedCategory.parentCategoryTitle
            : selectedCategory.rootCategoryTitle;
        coveoCmsEntityMapping.rootNodeType = "category";
      }
      coveoCmsEntityMappingList.push(coveoCmsEntityMapping);
    });
    this.selectedGrouphubs.map((selectedGrouphub) => {
      let coveoCmsEntityMapping: any = {};
      if (
        selectedGrouphub.boardId !== "" &&
        selectedGrouphub.boardId != undefined &&
        selectedGrouphub.boardId != null
      ) {
        coveoCmsEntityMapping.nodeId = selectedGrouphub.boardId;
        coveoCmsEntityMapping.nodeTitle = selectedGrouphub.boardTitle;
        coveoCmsEntityMapping.nodeType = "board";
        coveoCmsEntityMapping.interactionStyle = selectedGrouphub.boardStyle;
        coveoCmsEntityMapping.parentNodeId = selectedGrouphub.groupHubId;
        coveoCmsEntityMapping.parentNodeTitle = selectedGrouphub.groupHubTitle;
        coveoCmsEntityMapping.parentNodeType = "grouphub";
        coveoCmsEntityMapping.rootNodeId =
          selectedGrouphub.rootCategoryId == ""
            ? selectedGrouphub.parentCategoryId == ""
              ? selectedGrouphub.categoryId
              : selectedGrouphub.parentCategoryId
            : selectedGrouphub.rootCategoryId;
        coveoCmsEntityMapping.rootNodeTitle =
          selectedGrouphub.rootCategoryTitle == ""
            ? selectedGrouphub.parentCategoryTitle == ""
              ? selectedGrouphub.categoryTitle
              : selectedGrouphub.parentCategoryTitle
            : selectedGrouphub.rootCategoryTitle;
        coveoCmsEntityMapping.rootNodeType = "category";
      } else {
        coveoCmsEntityMapping.nodeId = selectedGrouphub.groupHubId;
        coveoCmsEntityMapping.nodeTitle = selectedGrouphub.groupHubTitle;
        coveoCmsEntityMapping.nodeType = "grouphub";
        coveoCmsEntityMapping.interactionStyle = "";
        coveoCmsEntityMapping.parentNodeId = selectedGrouphub.parentId;
        coveoCmsEntityMapping.parentNodeTitle = selectedGrouphub.parentTitle;
        coveoCmsEntityMapping.parentNodeType = selectedGrouphub.parentType;
        coveoCmsEntityMapping.rootNodeId = selectedGrouphub.rootCategoryId;
        coveoCmsEntityMapping.rootNodeTitle =
          selectedGrouphub.rootCategoryTitle;
        coveoCmsEntityMapping.rootNodeType =
          selectedGrouphub.rootCategoryTitle == "" ? "" : "category";
      }
      coveoCmsEntityMappingList.push(coveoCmsEntityMapping);
    });

    this.selectedCommunityBoards.map((selectedcommunityBoard) => {
      let coveoCmsEntityMapping: any = {};
      coveoCmsEntityMapping.nodeId = selectedcommunityBoard.boardId;
      coveoCmsEntityMapping.nodeTitle = selectedcommunityBoard.boardTitle;
      coveoCmsEntityMapping.nodeType = "board";
      coveoCmsEntityMapping.interactionStyle =
        selectedcommunityBoard.boardStyle;
      coveoCmsEntityMapping.parentNodeId = "";
      coveoCmsEntityMapping.parentNodeTitle = "";
      coveoCmsEntityMapping.parentNodeType = "community";
      coveoCmsEntityMapping.rootNodeId = "";
      coveoCmsEntityMapping.rootNodeTitle = "";
      coveoCmsEntityMapping.rootNodeType = "community";
      coveoCmsEntityMappingList.push(coveoCmsEntityMapping);
    });

    // let coveoSecurityidentityEnabled =
    //   (this.ConnectorForm.controls["coveoSecurityIdentityEnabled"] ===
    //     undefined &&
    //     this.ConnectorForm) ||
    //   this.ConnectorForm.controls["coveoSecurityIdentityEnabled"].value === ""
    //     ? false
    //     : this.ConnectorForm.controls["coveoSecurityIdentityEnabled"].value;
    // let coveoSecurityIdentityFieldName =
    //   this.ConnectorForm.controls["coveoSecurityIdentityFieldName"] ===
    //   undefined
    //     ? ""
    //     : this.ConnectorForm.controls["coveoSecurityIdentityFieldName"].value;
    // let coveoExtentionWithRebuildDataSourcesName =
    //   this.ConnectorForm.controls["coveoExtentionWithRebuildDataSourcesName"] === undefined
    //     ? ""
    //     : this.ConnectorForm.controls[
    //         "coveoExtentionWithRebuildDataSourcesName"
    //       ].value;
    let coveoMessagesEnabled =
      (this.ConnectorForm.controls["coveoMessagesEnabled"] === undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["coveoMessagesEnabled"].value === ""
        ? false
        : this.ConnectorForm.controls["coveoMessagesEnabled"].value;
    let privateMessagesEnabled =
      (this.ConnectorForm.controls["privateMessagesEnabled"] === undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["privateMessagesEnabled"].value === ""
        ? false
        : this.ConnectorForm.controls["privateMessagesEnabled"].value;
    // let coveoExtentionEnabled =
    //   this.ConnectorForm.controls["coveoExtentionEnabled"] === undefined
    //     ? false
    //     : this.ConnectorForm.controls["coveoExtentionEnabled"].value;
    // let coveoExtentionWithRebuildEnabled =
    //   (this.ConnectorForm.controls["coveoExtentionWithRebuildEnabled"] ===
    //     undefined &&
    //     this.ConnectorForm) ||
    //   this.ConnectorForm.controls["coveoExtentionWithRebuildEnabled"].value ===
    //     ""
    //     ? false
    //     : this.ConnectorForm.controls["coveoExtentionWithRebuildEnabled"].value;
    let khorosAllCommunity =
      (this.ConnectorForm.controls["khorosAllCommunity"] === undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["khorosAllCommunity"].value === ""
        ? false
        : this.ConnectorForm.controls["khorosAllCommunity"].value;
    let publicMessages =
      (this.ConnectorForm.controls["publicMessages"] === undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["publicMessages"].value === ""
        ? false
        : this.ConnectorForm.controls["publicMessages"].value;
    let grouphubsEnabled =
      (this.ConnectorForm.controls["groupHubsEnabled"] === undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["groupHubsEnabled"].value === ""
        ? false
        : this.ConnectorForm.controls["groupHubsEnabled"].value;
    let allGroupHubsEnabled =
      (this.ConnectorForm.controls["allGroupHubsEnabled"] === undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["allGroupHubsEnabled"].value === ""
        ? false
        : this.ConnectorForm.controls["allGroupHubsEnabled"].value;
    let allEventsOutSideGroupHubsEnabled =
      (this.ConnectorForm.controls["allEventsOutSideGroupHubsEnabled"] ===
        undefined &&
        this.ConnectorForm) ||
        this.ConnectorForm.controls["allEventsOutSideGroupHubsEnabled"].value ===
        ""
        ? false
        : this.ConnectorForm.controls["allEventsOutSideGroupHubsEnabled"].value;

    const payload = {
      connectorMapping: {
        id:
          this.selectedConnector === undefined || this.selectedConnector === ""
            ? null
            : this.selectedConnector.id,
        sourceCommunity: this.selectedSourceCommunity,
        targetCommunity: this.selectedTargetCommunity,
        customer: customer,
        entityType: entityType,
        syndicateSolutionMessage: this.syndicateSolutionMessage,
        bulkDataActionKeys: {
          messageCreate: this.messageCreate,
          messageUpdate: this.messageUpdate,
          messageDelete: this.messageDelete,
          solutionUpdate: this.solutionUpdate,
          moderationApproved: this.moderationApproved,
          spam: this.spam,
          ham: this.ham,
        },
        sourceCommunityId: parseInt(this.sourceCommunity),
        targetCommunityId: parseInt(this.targetCommunity),
        coveoMessagesEnabled: coveoMessagesEnabled,
        privateMessagesEnabled: privateMessagesEnabled,
        khorosAllCommunity: khorosAllCommunity,
        publicMessages: publicMessages,
        groupHubsEnabled: grouphubsEnabled,
        allGroupHubsEnabled: allGroupHubsEnabled,
        allEventsOutSideGroupHubsEnabled: allEventsOutSideGroupHubsEnabled,
        coveoCMSEntityMappings: coveoCmsEntityMappingList,
      },
      coveoCMSEntityMappings: coveoCmsEntityMappingList,
      fieldMappings: this.communityCustomFields,
      productBoardMappings: this.communityProductBoardFields,
      khorosConnectorDetails: {
        communicationEmail: communicationEmail,
        excludedRoles: excludedRoles,
        excludeEmailDomains: excludeEmailDomains,
      },
    };
    this.spinner = true;
    this.connectorService.saveFieldMappings(payload).subscribe(
      (res) => {
        this.spinner = false;
        this.reset();
        if (res.response.code === 409) {
          return this.snackBar.open(res.response.message, "Close", this.config);
        }
        this.fetchConnectorMappings();
        return this.snackBar.open(
          Constants.CONNECTOR_MAPPING_SAVED_SUCCESSFULLY,
          "Close",
          this.config
        );
      },
      (error) => {
        this.spinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );

    return;
  }

  fetchConnectorMappings() {
    if (this.superAdmin) {
      this.fetchCustomers(
        this.pageSize,
        this.currentPage,
        this.activeSort,
        this.sortOrder,
        this.searchString
      );
    } else {
      this.customer = {
        customerId: this.customerObj.id,
        customerName: this.customerObj.name,
      };
      this.customerId = this.customerObj.id;
      this.fetchCommunities(this.customerObj.id,false);
    }
  }

  enableRebuild(element: any): boolean {
    if (
      ((element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_KHOROS ||
        element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_KHOROS_CLASSIC) &&
        (element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_COVEO ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_AZURE_STORAGE ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_WEB_PROXY ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_GOOGLE_CLOUD_STORAGE ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_VERTEX_AI)) ||
      (element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_ZENDESK &&
        element.targetCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_SHAREPOINT_STORAGE_SERVICE) ||
      element.sourceCommunity.communityType.communityTypeName ===
      Constants.COMMUNITY_TYPE_SFDC
    ) {
      return true;
    }
    return false;
  }
  filterConnectorMappings(data: any) {
    // if (data === "") {
    //   this.filteredConnectorMappingsList = this.connectorMappings;
    // } else {
    //   let customerId = parseInt(data);
    //   this.filteredConnectorMappingsList = this.connectorMappings.filter(
    //     (obj) => obj.customer.customerId === customerId
    //   );
    // }
    // this.connectorMappingListDataSource = new MatTableDataSource(
    //   this.filteredConnectorMappingsList
    // );
    // this.connectorMappingListDataSource.paginator =
    //   this.viewConnectorMappingsPaginator;
    // this.connectorMappingListDataSource.sortingDataAccessor = (
    //   item,
    //   property
    // ) => {
    //   if (property.includes("."))
    //     return property.split(".").reduce((o, i) => o[i], item);
    //   return item[property];
    // };
    // this.connectorMappingListDataSource.sort = this.viewConnectorMappingsSort;
    // this.currentPage = 0;
    const selectedCustomer = data;
    this.setDefault();
    this.customerId = data;
    this.fetchConnectorMappings();
  }

  applyFilter(filterValue: string) {
    // this.connectorMappingListDataSource.data = this.connectorMappings;
    // this.connectorMappingListDataSource.filterPredicate = (data, filter) => {
    //   const customerName = (
    //     data.customer.customerName as string
    //   ).toLocaleLowerCase();
    //   const entityType = (data.entityType as string).toLocaleLowerCase();
    //   const sourceCommunity = (
    //     data.sourceCommunity.communityName as string
    //   ).toLocaleLowerCase();
    //   const targetCommunity = (
    //     data.targetCommunity.communityName as string
    //   ).toLocaleLowerCase();

    //   return (
    //     customerName.includes(filter) ||
    //     entityType.includes(filter) ||
    //     sourceCommunity.includes(filter) ||
    //     targetCommunity.includes(filter)
    //   );
    // };
    // this.connectorMappingListDataSource.filter = filterValue
    //   .trim()
    //   .toLowerCase();
    this.searchString = filterValue.trim().toLowerCase();
    if (this.superAdmin) {
      this.fetchCustomers(
        this.pageSize,
        0,
        this.activeSort,
        this.sortOrder,
        filterValue.trim().toLowerCase()
      );
    } else {
      this.customer = {
        customerId: this.customerObj.id,
        customerName: this.customerObj.name,
      };
      this.customerId = this.customerObj.id;
      this.fetchCommunities(this.customerObj.id,false);
    }
  }

  disableRescanButtons(element: any): boolean {
    if (
      ((element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_KHOROS ||
        element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_KHOROS_CLASSIC ||
        element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_KHOROS_AURORA) &&
        (element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_COVEO ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_AZURE_STORAGE ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_SFDC ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_WEB_PROXY ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_GOOGLE_CLOUD_STORAGE ||
          element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_VERTEX_AI || element.targetCommunity.communityType.communityTypeName ===
          Constants.COMMUNITY_TYPE_TEALIUM)) ||
      (element.sourceCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_ZENDESK &&
        element.targetCommunity.communityType.communityTypeName ===
        Constants.COMMUNITY_TYPE_SHAREPOINT_STORAGE_SERVICE) ||
      element.sourceCommunity.communityType.communityTypeName ===
      Constants.COMMUNITY_TYPE_SFDC
    ) {
      return false; // Buttons should not be disabled
    }
    return true; // Buttons should be disabled
  }

  uploadFile = (event) => {
    this.selectedFile = event.target.files[0];
    this.csvImportBoard.nativeElement.innerText = this.selectedFile.name;
  };

  submitFile = () => {
    const formData = new FormData();
    console.log("file ", this.selectedFile);
    if (
      this.sourceCommunity === null ||
      this.sourceCommunity === "" ||
      this.targetCommunity === null ||
      this.targetCommunity === ""
    ) {
      this.spinner = false;
      this.modalComponent.populateModal({
        response: { message: Constants.COMMUNITIES_EMPTY_ERROR_MSG },
      });
      return;
    }

    if (!this.selectedFile) {
      this.spinner = false;
      this.modalComponent.populateModal({
        response: { message: Constants.FILE_EMPTY_ERROR_MSG },
      });
      return;
    }

    formData.append("file", this.selectedFile);
    this.connectorService
      .submitFile(formData, this.sourceCommunity, this.targetCommunity)
      .subscribe(
        (res) => {
          this.fileInputRef.nativeElement.value = "";
          console.log("Successfully uploadedfile", res);
          return this.snackBar.open(
            "File uploaded successfully...",
            "Close",
            this.config
          );
        },
        (error) => {
          console.log("Failed to upload the file", error);
        }
      );
  };

  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "csv") {
      return true;
    } else {
      return false;
    }
  }

  csvDownlaod(event) {
    this.connectorService.downloadCSV().subscribe(
      (res: any) => {
        if (res.length > 0) {
          const csvRows = [];
          const headers = Object.keys(res[0]);
          csvRows.push(headers);
          for (const row of res) {
            const values = headers.map((header) => row[header]);
            csvRows.push(values);
          }
          const csvString = csvRows.map((row) => row.join(",")).join("\n");
          const blob = new Blob([csvString], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "MigrationInputdData.csv";
          link.click();
        }
      },
      (error) => {
        this.spinner = false;
        if (
          error.status === 401 &&
          error.error.response.status === "error" &&
          error.error.response.message === "UnAuthorized Access Token Expired"
        ) {
          return this.snackBar.open(
            Constants.SESSION_EXPIRED,
            "Close",
            this.config
          );
        } else {
          return this.snackBar.open(
            error.error.response.message,
            "Close",
            this.config
          );
        }
      }
    );
  }

  submitIds = () => {
    const createScheduleObj = {
      id: 0,
      connectorMapping: {
        ...this.selectedConnector,
      },
      idsProcessed: this.ConnectorForm.controls["messagesOrUserIds"].value,
      objectType: this.ConnectorForm.controls["objectType"].value,
    };
    this.connectorService
      .processMissingIds(createScheduleObj)
      .subscribe((res) => {
        this.spinner = false;
        if (res.response.code === 200 && res.response.status === "success") {
          return this.snackBar.open(
            Constants.SCHEDULE_CONFIGURED,
            "Close",
            this.config
          );
        }
      });
  };

  addEntity() {
    const entityTypeObj = this.fieldMappingEntityTypes.find(item => item.value === this.selectedFieldMappingEntityType);
    if (entityTypeObj) {
      const fieldMapping = this.fieldMappingList.find(item => item.key === this.selectedFieldMappingEntityType);
      if (!fieldMapping) {
        this.fieldMappingList.push({
          key: entityTypeObj.value,
          displayName: entityTypeObj.name,
          data: [],
          sourceFields: [],
          targetFields: []
        });
      } else {
        return this.snackBar.open(
          Constants.ENTITY_TYPE_EXISTS,
          "Close",
          this.config
        );
      }
    }
  }

  processAndFilterFieldMappings(fieldMappings: any[]) {
    this.fieldMappingList = [];
    let fieldMappingsGroupedByEntityType = fieldMappings.reduce((groups, item) => ((groups[item.entityType] = groups[item.entityType] || []).push(item), groups), {} as Record<string, any[]>);
    for (let [entityType, items] of Object.entries(fieldMappingsGroupedByEntityType)) {
      const entityTypeObj = this.fieldMappingEntityTypes.find(item => item.value === entityType);
      if (entityTypeObj && entityTypeObj.name) {
        this.fieldMappingList.push({
          key: entityType,
          displayName: entityTypeObj.name,
          data: items,
          sourceFields: [],
          targetFields: []
        });
      }
    }
  }

  processAndFilterProductBoardMappings(productBoardMappings: any[]) {
    this.productBoardMappingList = [];
    let fieldMappingsGroupedByEntityType = productBoardMappings.reduce((groups, item) => ((groups[item.entityType] = groups[item.entityType] || []).push(item), groups), {} as Record<string, any[]>);
    for (let [entityType, items] of Object.entries(fieldMappingsGroupedByEntityType)) {
      const entityTypeObj = this.fieldMappingEntityTypes.find(item => item.value === entityType);
      if (entityTypeObj && entityTypeObj.name) {
        this.productBoardMappingList.push({
          key: entityType,
          displayName: entityTypeObj.name,
          data: items,
          sourceFields: [],
          targetFields: []
        });
      }
    }
  }
}
