<!--Spinner-->
<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<form [formGroup]="communityInfoForm">
  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('communityName')">
      <div class="form-group">
        <label>Community name<span class="label-required">*</span></label>
        <input id="comm-name" formControlName="communityName" class="form-control" type="text"
          placeholder="Community name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['communityName'].hasError('required') && communityInfoForm.controls['communityName'].dirty">
          Community name is required.</p>
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['communityName'].hasError('pattern') && communityInfoForm.controls['communityName'].dirty">
          Community name can not contain special characters</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoExtensionId')">
      <div class="form-group">
        <label>Coveo Extension Id</label>
        <input id="coveo-extension-id" formControlName="coveoExtensionId" class="form-control" type="text"
          placeholder="Coveo Extension Id">
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoExcludedRoles')">
      <div class="form-group">
        <label>Roles to Exclude</label>
        <input id="coveo-excluded-roles" formControlName="excludedRoles" class="form-control" type="text"
          placeholder="Roles to be excluded">
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoIncludedRoles')">
      <div class="form-group">
        <label>Community Roles to Include</label>
        <input id="coveo-included-roles" formControlName="includedRoles" class="form-control" type="text"
          placeholder="Community level roles to included">
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('tenantID')">
      <div class="form-group">
        <label>Tenant ID<span class="label-required">*</span></label>
        <input id="comm-tenant-id" class="form-control" formControlName="tenantID" type="text" placeholder="Tenant ID">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['tenantID'].hasError('required') && communityInfoForm.controls['tenantID'].dirty">
          Tenant Id is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('zendeskScope')">
      <div class="form-group">
        <label>Scope<span class="label-required">*</span></label>
        <input id="comm-zendesk-scope" class="form-control" formControlName="zendeskScope" type="text"
          placeholder="Scope">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['zendeskScope'].hasError('required') && communityInfoForm.controls['zendeskScope'].dirty">
          Scope is required.</p>
      </div>
    </div>
    <div class="col-md-4" *ngIf="canShow('communityActAS')">
      <div class="form-group">
        <label>Community act as</label><br />
        <div class="radio-btns">
          <span>
            <input class="community-act-as-source" type="radio" id="source" name="communityActAS" value="SOURCE"
              formControlName="communityActAS">
            <label for="source">Source</label>
          </span>
          <span class="ml-4">
            <input class="community-act-as-target" type="radio" id="target" name="communityActAS" value="TARGET"
              formControlName="communityActAS">
            <label for="target">Target</label>
          </span>
          <span class="ml-4">
            <input class="community-act-as-both" type="radio" id="both" name="communityActAS" value="BOTH"
              formControlName="communityActAS">
            <label for="both">Both</label>
          </span>
        </div>
      </div>
    </div>

  </div>

  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('communityURL')">
      <div class="form-group">
        <label class="font-weight-bold small">Community URL<span class="label-required">*</span></label>
        <input id="comm-url" class="form-control" formControlName="communityURL" type="text"
          placeholder="eg: https://communityurl.com">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['communityURL'].hasError('required') && communityInfoForm.controls['communityURL'].dirty">
          Community url is required.</p>
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['communityURL'].hasError('pattern') && communityInfoForm.controls['communityURL'].dirty">
          Community url should be a valid URL</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('apiHostURL')">
      <div class="form-group">
        <label>API host URL<span class="label-required">*</span></label>
        <input id="comm-api-host-url" class="form-control" formControlName="apiHostURL" type="text"
          placeholder="https://api.stage.com">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['apiHostURL'].hasError('required') && communityInfoForm.controls['apiHostURL'].dirty">
          API url is required.</p>
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['apiHostURL'].hasError('pattern') && communityInfoForm.controls['apiHostURL'].dirty">
          API url should be a valid URL</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('smartsheetFolderId')">
      <div class="form-group">
        <label>SmartSheet Folder ID<span class="label-required">*</span></label>
        <input id="comm-api-host-url" class="form-control" formControlName="smartsheetFolderId" type="text">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['smartsheetFolderId'].hasError('required') && communityInfoForm.controls['smartsheetFolderId'].dirty">
          Folder ID is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('templateSheetId')">
      <div class="form-group">
        <label>Template Sheet ID<span class="label-required">*</span></label>
        <input id="comm-template-sheet-id" class="form-control" formControlName="templateSheetId" type="text">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['templateSheetId'].hasError('required') && communityInfoForm.controls['templateSheetId'].dirty">
          Template Sheet ID is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('chamaEndpointUrl')">
      <div class="form-group">
        <label>Chama Endpoint URL<span class="label-required">*</span></label>
        <input id="comm-template-sheet-id" class="form-control" formControlName="chamaEndpointUrl" type="text">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['chamaEndpointUrl'].hasError('required') && communityInfoForm.controls['chamaEndpointUrl'].dirty">
          Chama Endpoint URL is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('awsEndpoint')">
      <div class="form-group">
        <label>AWS Endpoint<span class="label-required">*</span></label>
        <input id="comm-template-sheet-id" class="form-control" formControlName="awsEndpoint" type="text">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['awsEndpoint'].hasError('required') && communityInfoForm.controls['awsEndpoint'].dirty">
          AWS Endpoint is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('awsS3Url')">
      <div class="form-group">
        <label>AWS S3 URL<span class="label-required">*</span></label>
        <input id="comm-template-sheet-id" class="form-control" formControlName="awsS3Url" type="text">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['awsS3Url'].hasError('required') && communityInfoForm.controls['awsS3Url'].dirty">
          AWS S3 URL is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('registrationURL')">
      <div class="form-group">
        <label>Registration URL<span class="label-required">*</span></label>
        <input id="comm-reg-url" class="form-control" formControlName="registrationURL" type="text"
          placeholder="Registration URL">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['registrationURL'].hasError('required') && communityInfoForm.controls['registrationURL'].dirty">
          Registration url is required.</p>
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['registrationURL'].hasError('pattern') && communityInfoForm.controls['registrationURL'].dirty">
          Registartion url should be a valid URL</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('linkedInpageId')">
      <div class="form-group">
        <label>LinkedIn Page ID<span class="label-required">*</span></label>
        <input id="comm-linkedin-page-url" class="form-control" formControlName="linkedInpageId" type="text"
          placeholder="LinkedIn Page Id">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['linkedInpageId'].hasError('required') && communityInfoForm.controls['linkedInpageId'].dirty">
          Page ID is required.</p>

      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('subredditName')">
      <div class="form-group">
        <label>SubReddit Name<span class="label-required">*</span></label>
        <input id="comm-subreddit-name" class="form-control" formControlName="subredditName" type="text"
          placeholder="Subreddit Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['subredditName'].hasError('required') && communityInfoForm.controls['subredditName'].dirty">
          SubReddit Name is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('kbAPIName')">
      <div class="form-group">
        <label>Knowledge API Name<span class="label-required">*</span></label>
        <input id="comm-kb-api-name" class="form-control" formControlName="kbAPIName" type="text"
          placeholder="Knowledge API Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['kbAPIName'].hasError('required') && communityInfoForm.controls['kbAPIName'].dirty">
          Knowledge API Name is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('projectId')">
      <div class="form-group">
        <label>Project Id<span class="label-required">*</span></label>
        <input id="comm-project-id" class="form-control" formControlName="projectId" type="text"
          placeholder="Project Id">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['projectId'].hasError('required') && communityInfoForm.controls['projectId'].dirty">
          Project Id is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('bucketName')">
      <div class="form-group">
        <label>Bucket Name<span class="label-required">*</span></label>
        <input id="comm-bucket-name" class="form-control" formControlName="bucketName" type="text"
          placeholder="Bucket Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['bucketName'].hasError('required') && communityInfoForm.controls['bucketName'].dirty">
          Bucket Name is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('datastoreURL')">
      <div class="form-group">
        <label>Datastore URL<span class="label-required">*</span></label>
        <input id="comm-datastore-url" class="form-control" formControlName="datastoreURL" type="text"
          placeholder="Datastore URL">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['datastoreURL'].hasError('required') && communityInfoForm.controls['datastoreURL'].dirty">
          Datastore URL is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('datastoreId')">
      <div class="form-group">
        <label>Datastore Id<span class="label-required">*</span></label>
        <input id="comm-datastore-id" class="form-control" formControlName="datastoreId" type="text"
          placeholder="Datastore Id">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['datastoreId'].hasError('required') && communityInfoForm.controls['datastoreId'].dirty">
          Datastore Id is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('authenticationType')">
      <div class="form-group">
        <label>authenticationType<span class="label-required">*</span></label>
        <select formControlName="authenticationType" class="form-control">
          <option value="">select</option>
          <option value="apikey">API Key</option>
          <option value="mtls">mTLS</option>
        </select>
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['authenticationType'].hasError('required') && communityInfoForm.controls['authenticationType'].dirty">
          Please select the authentication type</p>
      </div>
    </div>
      <div class="col-md-3" *ngIf="canShow('account') ">
        <div class="form-group">
          <label>Account<span class="label-required">*</span></label>
          <input id="comm-account" class="form-control" formControlName="account" type="text"
            placeholder="Account Name">
          <p class="custom-error"
            *ngIf="communityInfoForm.controls['account'].hasError('required') && communityInfoForm.controls['account'].dirty">
            Account Name is required.</p>
        </div>
      </div>
      <div class="col-md-3" *ngIf="canShow('profile') ">
        <div class="form-group">
          <label>Profile<span class="label-required">*</span></label>
          <input id="comm-profile" class="form-control" formControlName="profile" type="text"
            placeholder="Profile">
          <p class="custom-error"
            *ngIf="communityInfoForm.controls['profile'].hasError('required') && communityInfoForm.controls['profile'].dirty">
            Profile is required.</p>
        </div>
  

    </div>
    <div class="col-md-3" *ngIf="canShow('includedRoles')">
      <div class="form-group">
        <label>Included Roles<span class="label-required">*</span></label>
        <input id="comm-included-roles" class="form-control" formControlName="includedRoles" type="text"
          placeholder="Enter included roles, separated by commas">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['includedRoles'].hasError('required') && communityInfoForm.controls['includedRoles'].dirty">
          Table Name is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('tableName')">
      <div class="form-group">
        <label>Table Name<span class="label-required">*</span></label>
        <input id="comm-table-name" class="form-control" formControlName="tableName" type="text"
          placeholder="Table Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['tableName'].hasError('required') && communityInfoForm.controls['tableName'].dirty">
          Table Name is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('dataSet')">
      <div class="form-group">
        <label>DataSet<span class="label-required">*</span></label>
        <input id="comm-dataset" class="form-control" formControlName="dataSet" type="text"
          placeholder="DataSet Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['dataSet'].hasError('required') && communityInfoForm.controls['dataSet'].dirty">
          DataSet  is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('datasource') ">
      <div class="form-group">
        <label>Datasource<span class="label-required">*</span></label>
        <input id="comm-datasource" class="form-control" formControlName="datasource" type="text"
          placeholder="Datasource Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['datasource'].hasError('required') && communityInfoForm.controls['datasource'].dirty">
          Datasource Name is required.</p>
      </div>
    </div>

    <div class="gcp-key"  *ngIf="communityTypeName == 'GOOGLE-CLOUD-STORAGE' || communityTypeName === 'VERTEX-AI'">
      <div class="col-xs-3 form-inline">
        <label for="exampleInputAmount" class="file-upload" style="padding-left: 20px !important">gcp-key: 
          &nbsp;&nbsp;&nbsp;&nbsp;
        </label>
        <div class="input-group col-xs-3">
          <div class="custom-file">
            <input type="file" accept=".json" class="form-control custom-file-input" id="importFile" multiple (change)="gcpKeyFileChange($event.target.files)" />
            <label class="custom-file-label" id="gcpKeys" #gcpKeyLabel for="importFile"><i
                class="fa fa-upload"></i> &nbsp; &nbsp; &nbsp; Choose
              file</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mTLS-key" *ngIf="canShow('authenticationType') && communityInfoForm.controls['authenticationType'].value != '' && communityInfoForm.controls['authenticationType'].value == 'mtls'">
      <div class="col-xs-3 form-inline">
        <label for="exampleInputAmount" class="file-upload" style="padding-left: 20px !important">mTLS-key: 
          &nbsp;&nbsp;&nbsp;&nbsp;
        </label>
        <div class="input-group col-xs-3">
          <div class="custom-file" [ngClass]="[isValidated? 'mtlsDisabled' :'']">
            <input type="file" accept=".pem,.key" class="form-control custom-file-input" id="importFile" (change)="mtlsKeyFileChange($event.target.files)" />
            <label class="custom-file-label" id="mTLSKeys" #mTLSKeyLabel for="importFile"><i
                class="fa fa-upload"></i> &nbsp; &nbsp; &nbsp; Choose
              file</label>
          </div>
        </div>
      </div>
    </div> 
    <div class="mTLS-certificate" *ngIf="canShow('authenticationType') && communityInfoForm.controls['authenticationType'].value != '' && communityInfoForm.controls['authenticationType'].value == 'mtls'">
      <div class="col-xs-3 form-inline">
        <label for="exampleInputAmount" class="file-upload" style="padding-left: 20px !important">mTLS-certificate:
          &nbsp;&nbsp;&nbsp;&nbsp;  
        </label>
        <div class="input-group col-xs-3">
          <div class="custom-file" [ngClass]="[isValidated? 'mtlsDisabled' :'']">
            <input type="file" accept=".pem,.key" class="form-control custom-file-input"  id="importFile" (change)="mTLSCertificateChange($event.target.files)" />
            <label class="custom-file-label" id="mTLSCertificates" #mTLSCertificateLabel for="importFile"><i class="fa fa-upload"></i>
              &nbsp; &nbsp; &nbsp; Choose
              file</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('higherlogicCommunityName')">
      <div class="form-group">
        <label>Higherlogic Community Name<span class="label-required">*</span></label>
        <input id="comm-higherlogic-community-name" class="form-control" formControlName="higherlogicCommunityName" type="text"
          placeholder="Higherlogic Community Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['higherlogicCommunityName'].hasError('required') && communityInfoForm.controls['higherlogicCommunityName'].dirty">
          Higherlogic Community Name is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('sharepointStorageId')">
      <div class="form-group">
        <label>Share Point Storage Id <span class="label-required">*</span></label>
        <input id="comm-sharepoint-storage-id" class="form-control" formControlName="sharepointStorageId" type="text"
          placeholder="Share Point Storage Id">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['sharepointStorageId'].hasError('required') && communityInfoForm.controls['sharepointStorageId'].dirty">
          Share Point Storage Id is required.</p>
      </div>
    </div>
  </div>

  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('validateCommunityBy')">
      <div class="form-group">
        <label>Validate by<span class="label-required">*</span></label>
        <select id="comm-validate-by" class="form-control" formControlName="validateCommunityBy">
          <option [disabled]="isUpdate" value="">select</option>
          <option [disabled]="isUpdate" value="email">Email</option>
          <option [disabled]="isUpdate" value="ssoid">SSOID</option>
        </select>
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['validateCommunityBy'].hasError('required') && communityInfoForm.controls['validateCommunityBy'].dirty">
          Please select the type of validation</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('is_category_as_community')">
      <!-- <label class="chkBoxContainer">
        <span class="inter-font">Category as community</span>
        <input id="comm-cat-as-comm" type="checkbox" formControlName="is_category_as_community">
        <span class="checkmark"></span>
      </label> -->
      <label>Node Type<span class="label-required">*</span></label>
      <select formControlName="nodeType" class="form-control">
        <option value="">Select</option>
        <option value="community">Community</option>
        <option value="category">Category</option>
        <option value="grouphub">GroupHub</option>
        <option value="board">Board</option>
      </select>
      <p class="custom-error"
          *ngIf="communityInfoForm.controls['nodeType'].hasError('required') && communityInfoForm.controls['nodeType'].dirty">
          Please select the node type</p>
    </div>
    <div class="col-md-3" *ngIf="canShow('hiddenBoardIds')">
      <div class="form-group">
        <label>Hidden Boards<span class="label-required">*</span></label>
        <input id="comm-hidden-boards" class="form-control" formControlName="hiddenBoardIds" type="text"
          placeholder="Hidden Boards">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['hiddenBoardIds'].hasError('required') && communityInfoForm.controls['hiddenBoardIds'].dirty">
          Hidden Boards are required.</p>
      </div>
    </div>
    <div class="col-md-3 form-group" *ngIf="canShow('is_category_as_community')">
      <div *ngIf="communityInfoForm.controls['nodeType']?.value != '' && communityInfoForm.controls['nodeType']?.value != 'community'">
        <label>Node ID<span class="label-required">*</span></label>
        <input id="comm-cat-id" class="form-control" formControlName="nodeId" type="text" placeholder="Node ID">
      </div>
    </div>
  </div>

  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('tags')">
      <label class="chkBoxContainer">
        <span class="inter-font">Syndicate Tags</span>
        <input id="syndicate-tags-enabled" type="checkbox" formControlName="syndicateTags">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('labels')">
      <label class="chkBoxContainer">
        <span class="inter-font">Syndicate Labels</span>
        <input id="syndicate-labels-enabled" type="checkbox" formControlName="syndicateLabels">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('khorosPullMechanismEnabled')">
      <label class="chkBoxContainer">
        <span class="inter-font">Enable 1hr Pull Mechanism</span>
        <input id="comm-khoros-pull-enabled" type="checkbox" formControlName="khorosPullMechanismEnabled">
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="col-md-3" *ngIf="canShow('resourceType')">
      <div class="form-group">
        <label>Resource Type</label>
        <input id="resource-type" formControlName="resourceType" class="form-control" type="text"
          placeholder="Resource Type">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['resourceType'].hasError('required') && communityInfoForm.controls['resourceType'].dirty">
          Resource Type is required</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('grantType')">
      <div class="form-group">
        <label>Grant Type </label>
        <input id="grantType" formControlName="grantType" class="form-control" type="text" placeholder="Grant Type">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['grantType'].hasError('required') && communityInfoForm.controls['grantType'].dirty">
          Grant Type is required</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('siteName')">
      <div class="form-group">
        <label>Site Name</label>
        <input id="labels" formControlName="siteName" class="form-control" type="text" placeholder="Site Name">
        <p class="custom-error"
          *ngIf="communityInfoForm.controls['siteName'].hasError('required') && communityInfoForm.controls['siteName'].dirty">
          Site Name is required</p>
      </div>
    </div>

  </div>

  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('isEnabledPostAsMachineUser')">
      <label class="chkBoxContainer">
        <span class="inter-font">Post As Machine User</span>
        <input id="post-as-machine-user" type="checkbox" formControlName="postAsMachineUser">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('isEnabledCanonicalUrl')">
      <label class="chkBoxContainer">
        <span class="inter-font">Syndicate Canonical URL</span>
        <input id="canonical-url-enabled" type="checkbox" formControlName="canonicalUrlEnabled">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('isEnabledMessageUpdateSyndication')">
      <label class="chkBoxContainer">
        <span class="inter-font">Target Message Updates Syndication</span>
        <input id="target-update-enabled" type="checkbox" formControlName="targetMessageUpdatesSyndication">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('isEnabledTargetThreadRepliesSyndication')">
      <label class="chkBoxContainer">
        <span class="inter-font">Target Thread Replies Syndication</span>
        <input id="canonical-url-enabled" type="checkbox" formControlName="targetThreadRepliesSyndication">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('isEnabledSLEUpdate')">
      <label class="chkBoxContainer">
        <span class="inter-font">Khoros SLE Update</span>
        <input id="khoros-sle-update-enabled" type="checkbox" formControlName="khorosSleUpdate">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('isEnabledHierarchicalLabels')">
      <label class="chkBoxContainer">
        <span class="inter-font">Hierarchical Labels</span>
        <input id="hierarchical-labels-enabled" type="checkbox" formControlName="hierarchicalLabels">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('isEnabledMessageHiddenTagSyndication')">
      <label class="chkBoxContainer">
        <span class="inter-font">Message Hidden Tag Syndication</span>
        <input id="message-hiddentag-enabled" type="checkbox" formControlName="messageHiddenTagSyndication">
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col-md-3" *ngIf="canShow('fieldMappingEnabled')">
      <label class="chkBoxContainer">
        <span class="inter-font">FieldMappingEnabled</span>
        <input id="fieldMapping-enabled" type="checkbox" formControlName="fieldMappingEnabled">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>

  <div class="row col-md-12 comm-icstenant"
    *ngIf="communityInfoForm.controls['icsTenantId']?.value !== null && communityInfoForm.controls['icsTenantId']?.value !== ''">

    <div class="col-md-3" *ngIf="canShow('icsTenantId')">
      <div class="form-group">
        <label>ICS Tenant ID</label>
        <input [readOnly]="isUpdate" id="ics-tenant-id" class="form-control" formControlName="icsTenantId" type="text"
          placeholder="Automatically generation">

        <p class="custom-error"
          *ngIf="communityInfoForm.controls['icsTenantId'].hasError('pattern') && communityInfoForm.controls['icsTenantId'].dirty">
          Space is not allowed</p>
      </div>
      <input id="comm-icstenant-regenerate-btn" type="button" value="Regenerate"
        class="tn bg-blue btn-flat margin btn-pdt" (click)="icsTenadntIdRegeneration()">
    </div>
  </div>
</form>
