<!--Spinner-->
<div class="spinner" *ngIf="showSpinner">
  <img src="../../../../assets/images/spinner.gif" height="50px">
</div>
<form [formGroup]="accessDetailsForm">
  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('autthenicationToken')">
      <div class="form-group">
        <label>Authentication token<span class="label-required">*</span></label>
        <!--matTooltip & placeholder will modified bcasue in LITHIUM
          it should be username:password is base 64 encode
          where as in TELLIGENT it should be API KEY:password base 64 encode-->
        <input id="comm-auth-token" formControlName="autthenicationToken" class="form-control"
          matTooltip="username or API key:password base64 encoded format" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="username or API key:password base64 format">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['autthenicationToken'].hasError('required') && accessDetailsForm.controls['autthenicationToken'].dirty">
          Authenticationtoken is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('environment')">
      <div class="form-group">
        <label>Environment<span class="label-required">*</span></label>
        <select id="comm-envi-type" class="form-control" formControlName="environment" (change)="checkHtAccess($event)">
          <option [disabled]="isUpdate" value="">select</option>
          <option [disabled]="isUpdate" *ngFor="let environment of environmentTypes"
            value={{environment.environmentTypeId}}>
            {{environment.environmentType}}
          </option>
        </select>
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['environment'].hasError('required') && accessDetailsForm.controls['environment'].dirty">
          Select environment type</p>
      </div>
    </div>
    <!-- Zendesk DB Service -->
    <div class="col-md-3" *ngIf="canShow('zendeskDatabaseUserName')">
      <div class="form-group">
        <label>DataBase UserName<span class="label-required">*</span></label>
        <input id="comm-database-username" class="form-control" formControlName="zendeskDatabaseUserName" type="text"
          placeholder="DataBase UserName">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['zendeskDatabaseUserName'].hasError('required') && accessDetailsForm.controls['zendeskDatabaseUserName'].dirty">
          DataBase UserName is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('zendeskDatabasePassword')">
      <div class="form-group">
        <label>DataBase Password<span class="label-required">*</span></label>
        <input id="comm-database-password" class="form-control" formControlName="zendeskDatabasePassword" type="text"
          placeholder="DataBase Password">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['zendeskDatabasePassword'].hasError('required') && accessDetailsForm.controls['zendeskDatabasePassword'].dirty">
          DataBase Password is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('zendeskDatabaseServer')">
      <div class="form-group">
        <label>DataBase Server<span class="label-required">*</span></label>
        <input id="comm-database-server" class="form-control" formControlName="zendeskDatabaseServer" type="text"
          placeholder="DataBase Server">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['zendeskDatabaseServer'].hasError('required') && accessDetailsForm.controls['zendeskDatabaseServer'].dirty">
          DataBase Server is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('zendeskDatabaseName')">
      <div class="form-group">
        <label>DataBase Name<span class="label-required">*</span></label>
        <input id="comm-database-name" class="form-control" formControlName="zendeskDatabaseName" type="text"
          placeholder="DataBase Name">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['zendeskDatabaseName'].hasError('required') && accessDetailsForm.controls['zendeskDatabaseName'].dirty">
          Database Name is required.</p>
      </div>
    </div>
    <!-- Added Tenant Name field for SharePoint community type-->
    <div class="col-md-3" *ngIf="canShow('tenantName')">
      <div class="form-group">
        <label>Tenant Name<span class="label-required">*</span></label>
        <input id="comm-client-id" class="form-control" formControlName="tenantName" type="text"
          placeholder="Tenant Name">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['tenantName'].hasError('required') && accessDetailsForm.controls['tenantName'].dirty">
          Tenant Name is required.</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group" *ngIf="isHtAccessEnabled && accessDetailsForm.controls['environment'].value !== ''">
        <label>HtAccess</label>
        <input id="comm-ht-access" class="form-control" matTooltip="htaccess username:password base64 encoded format"
          [matTooltipPosition]="'above'" aria-label="Button that displays a tooltip in various positions"
          formControlName="htAccess" type="text" placeholder="username:password base64 format">
        <!--<p class="custom-error" *ngIf="communitytForm.controls['htAccess'].hasError('required') && communitytForm.controls['htAccess'].dirty">HtAccess is required.</p>-->
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('securityIdentityProviderName')">
      <div class="form-group">
        <label>SecurityIdentity Provider Name<span class="label-required">*</span></label>
        <input id="comm-client-id" class="form-control" formControlName="securityIdentityProviderName" type="text"
          placeholder="SecurityIdentity Provider Name">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['securityIdentityProviderName'].hasError('required') && accessDetailsForm.controls['securityIdentityProviderName'].dirty">
          Security Identity Provider Name is required.</p>
      </div>
    </div>
  </div>

  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('clientID')">
      <div class="form-group">
        <label>Client ID<span class="label-required">*</span></label>
        <input id="comm-client-id" class="form-control" formControlName="clientID" type="password"
          placeholder="Client ID">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['clientID'].hasError('required') && accessDetailsForm.controls['clientID'].dirty">
          Client ID is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('clientSecret')">
      <div class="form-group">
        <label>Client secret<span class="label-required">*</span></label>
        <input id="comm-client-secrect" class="form-control" formControlName="clientSecret" type="password"
          placeholder="Client Secret">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['clientSecret'].hasError('required') && accessDetailsForm.controls['clientSecret'].dirty">
          Client secret is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('communityUserAuthenticationToken')">
      <div class="form-group">
        <label>Community User Authentication token<span class="label-required"></span></label>
        <!--matTooltip & placeholder will modified bcasue in LITHIUM
          it should be username:password is base 64 encode
          where as in TELLIGENT it should be API KEY:password base 64 encode-->
        <input id="comm-user-auth-token" formControlName="communityUserAuthenticationToken" class="form-control"
          matTooltip="username or API key:password base64 encoded format" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="username or API key:password base64 format">
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('verifyToken')">
      <div class="form-group">
        <label>Verify token<span class="label-required">*</span></label>
        <input id="comm-verify-token" formControlName="verifyToken" class="form-control"
          matTooltip="Verify Token is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="VerifyToken is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['verifyToken'].hasError('required') && accessDetailsForm.controls['verifyToken'].dirty">
          Verify token is required.</p>
      </div>
    </div>
    <div class="col-md-3"
      *ngIf="canShow('azureStorageUrl') && ((['KHOROS', 'KHOROS-AURORA'].includes(communityTypeName) && accessDetailsForm.controls['environment'].value !== '' ) || (communityTypeName == 'AZURE-STORAGE'))">
      <div class="form-group">
        <label>Storage Account Name</label>
        <input id="comm-client-id" class="form-control" formControlName="azureStorageConnectionUrl" type="text"
          placeholder="Azure Account Name">
        <!-- <p class="custom-error"
          *ngIf="accessDetailsForm.controls['azureStorageConnectionUrl'].hasError('required') && accessDetailsForm.controls['azureStorageConnectionUrl'].dirty">
          Storage Account Name is required.</p> -->
      </div>
    </div>
    <!-- Azure Authentication Type -->
    <div class="col-md-3"
    *ngIf="canShow('azureauthtype') && 
    ((['KHOROS', 'KHOROS-AURORA'].includes(communityTypeName) && accessDetailsForm.controls['environment'].value !== '') || 
     (communityTypeName == 'AZURE-STORAGE'))">
      <div class="form-group">
        <label>Authentication Type</label>
        <select id="comm-envi-type" class="form-control" formControlName="azureauthtype" (change)="checkAuthenticationType($event)">
          <option value="">select</option>
          <option value="ManagedIdentity">
            ManagedIdentity
          </option>
          <option value="ConnectionString">
            ConnectionString
          </option>
        </select>
        <!-- <p class="custom-error"
          *ngIf="accessDetailsForm.controls['azureauthtype'].hasError('required') && accessDetailsForm.controls['azureauthtype'].dirty">
          Select AuthenticationType</p> -->
      </div>
    </div>
    <div class="col-md-4" *ngIf="isStorageType && accessDetailsForm.controls['azureauthtype'].value !== ''">
      <div class="form-group">
        <label>Storage Type</label><br />
        <div class="radio-btns">
          <span>
            <span class="storagefile">
            <input class="storagetype-file" formControlName="storageType" type="radio" id="azurefile" name="storageType" value="file">
            <label for="file">file</label>
            </span>
            <span class="storageblob">
            <input class="storagetype-blob" formControlName="storageType" type="radio" id="azureblob" name="storageType" value="blob">
            <label for="blob">Blob</label>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-3" *ngIf="azureAuthType == 'ManagedIdentity' && this.accessDetailsForm.controls['storageType'].value == 'file'">
      <div class="form-group">
        <label>Resource Group</label>
        <input id="comm-azure-resource-group" class="form-control" formControlName="resourceGroup" type="text"
          placeholder="Resource Group" name="resourcegroup">
        <!-- <p class="custom-error"
          *ngIf="accessDetailsForm.controls['resourceGroup'].hasError('required') && accessDetailsForm.controls['resourceGroup'].dirty">
          Resource Group is required.</p> -->
      </div>
    </div>
    <div class="col-md-3" *ngIf="azureAuthType == 'ManagedIdentity' && this.accessDetailsForm.controls['storageType'].value == 'file'">
      <div class="form-group">
        <label>Subscription ID</label>
        <input id="comm-azure-subscription-id" class="form-control" formControlName="subscriptionId" type="text"
          placeholder="Subscription ID" name="subscriptionid">
        <!-- <p class="custom-error"
          *ngIf="accessDetailsForm.controls['subscriptionId'].hasError('required') && accessDetailsForm.controls['subscriptionId'].dirty">
          Subscription ID is required.</p> -->
      </div>
    </div>
    <div class="col-md-3"
      *ngIf="canShow('azureFileShare') && ((['KHOROS', 'KHOROS-AURORA'].includes(communityTypeName) && accessDetailsForm.controls['environment'].value !== '' ) || (communityTypeName == 'AZURE-STORAGE'))">
      <div class="form-group">
        <label>Azure File Share</label>
        <input id="comm-azure-file-share" class="form-control" formControlName="azureFileShare" type="text"
          placeholder="File Share">
        <!-- <p class="custom-error"
          *ngIf="accessDetailsForm.controls['azureFileShare'].hasError('required') && accessDetailsForm.controls['azureFileShare'].dirty">
          Azure File Share is required.</p> -->
      </div>
    </div>
    <div class="col-md-3"
      *ngIf="canShow('azureFolder') && ((['KHOROS', 'KHOROS-AURORA'].includes(communityTypeName) && accessDetailsForm.controls['environment'].value !== '' ) || (communityTypeName == 'AZURE-STORAGE'))">
      <div class="form-group">
        <label>Directory</label>
        <input id="comm-azure-folder" formControlName="azureFolder" class="form-control"
          matTooltip="Directory Name is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="Directory Name is required">
        <!-- <p class="custom-error"
          *ngIf="accessDetailsForm.controls['azureFolder'].hasError('required') && accessDetailsForm.controls['azureFolder'].dirty">
          Directory Name is required.</p> -->
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoOrganizationId')">
      <div class="form-group">
        <label>Organization Id<span class="label-required">*</span></label>
        <input id="comm-org-id" class="form-control" formControlName="coveoOrganizationId" type="text"
          placeholder="Coveo Organization Id">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['coveoOrganizationId'].hasError('required') && accessDetailsForm.controls['coveoOrganizationId'].dirty">
          Organization Id is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoDatasourceId')">
      <div class="form-group">
        <label> DataSource Id(s)<span class="label-required">*</span></label>
        <input id="comm-datasource-id" class="form-control" formControlName="coveoDatasourceId" type="text"
          placeholder="Data Source Ids by comma separated">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['coveoDatasourceId'].hasError('required') && accessDetailsForm.controls['coveoDatasourceId'].dirty">
          DataSource Id is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoAPIKey')">
      <div class="form-group">
        <label>API Key<span class="label-required">*</span></label>
        <input id="comm-coveo-apikey" formControlName="coveoAPIKey" class="form-control"
          matTooltip="API Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="API Key is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['coveoAPIKey'].hasError('required') && accessDetailsForm.controls['coveoAPIKey'].dirty">
          API Key is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('coveoFetchAPIKey')">
      <div class="form-group">
        <label>Coveo Fetch API Key</label>
        <input id="comm-coveo-fetchapikey" formControlName="coveoFetchAPIKey" class="form-control"
          matTooltip="Fetch API Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="Fetch API Key is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['coveoFetchAPIKey'].hasError('required') && accessDetailsForm.controls['coveoFetchAPIKey'].dirty">
         Fetch API Key is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('awsAPIKey')">
      <div class="form-group">
        <label>AWS API Key<span class="label-required">*</span></label>
        <input id="comm-aws-apikey" formControlName="awsAPIKey" class="form-control"
          matTooltip="API Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="API Key is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['awsAPIKey'].hasError('required') && accessDetailsForm.controls['awsAPIKey'].dirty">
          API Key is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('chamaAPIKey')">
      <div class="form-group">
        <label>Chama API Key<span class="label-required">*</span></label>
        <input id="comm-chama-apikey" formControlName="chamaAPIKey" class="form-control"
          matTooltip="API Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="API Key is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['chamaAPIKey'].hasError('required') && accessDetailsForm.controls['chamaAPIKey'].dirty">
          API Key is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('chamaMACKey')">
      <div class="form-group">
        <label>Chama MAC Key<span class="label-required">*</span></label>
        <input id="comm-chama-apikey" formControlName="chamaMACKey" class="form-control"
          matTooltip="Chama MAC Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="Chama MAC Key is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['chamaMACKey'].hasError('required') && accessDetailsForm.controls['chamaMACKey'].dirty">
          Chama MAC Key is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('smartsheetAPIKey')">
      <div class="form-group">
        <label>SmartSheet API Key<span class="label-required">*</span></label>
        <input id="comm-smartsheet-apikey" formControlName="smartsheetAPIKey" class="form-control"
          matTooltip="API Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="API Key is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['smartsheetAPIKey'].hasError('required') && accessDetailsForm.controls['smartsheetAPIKey'].dirty">
          API Key is required.</p>
      </div>
    </div>
    <!-- <div class="col-md-3" *ngIf="canShow('smartlingSignature')">
      <div class="form-group">
        <label>SmartLing Signature<span class="label-required">*</span></label>
        <input id="comm-smartling-signature" formControlName="smartlingSignature" class="form-control"
          matTooltip="Signature Key is required" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text"
          placeholder="SmartLing Signature is required">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['smartlingSignature'].hasError('required') && accessDetailsForm.controls['smartlingSignature'].dirty">
          Sgnature is required.</p>
      </div>
    </div> -->
  </div>

  <div class="row col-md-12">
    <div class="col-md-3" *ngIf="canShow('accessToken')">
      <div class="form-group">
        <label>Access token<span class="label-required">*</span></label>
        <input id="comm-access-token" formControlName="accessToken" class="form-control"
          matTooltip="Community Access Token" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text" placeholder="Access token">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['accessToken'].hasError('required') && accessDetailsForm.controls['accessToken'].dirty">
          Access token is required</p>
      </div>
    </div>

    <div class="col-md-3" *ngIf="canShow('refreshToken')">
      <div class="form-group">
        <label>Refresh token<span class="label-required">*</span></label>
        <input id="comm-refresh-token" formControlName="refreshToken" class="form-control"
          matTooltip="Community Refresh Token" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text" placeholder="Refresh token">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['refreshToken'].hasError('required') && accessDetailsForm.controls['refreshToken'].dirty">
          Refresh token is required</p>
      </div>
    </div>
    <!-- Added Access Token URL field for share point community type -->
    <div class="col-md-3" *ngIf="canShow('accessTokenURL')">
      <div class="form-group">
        <label>Access token URL<span class="label-required">*</span></label>
        <input id="comm-access-token-url" formControlName="accessTokenURL" class="form-control"
          matTooltip="Community Access Token URL" [matTooltipPosition]="'above'"
          aria-label="Button that displays a tooltip in various positions" type="text" placeholder="Access token URL">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['accessTokenURL'].hasError('required') && accessDetailsForm.controls['accessTokenURL'].dirty">
          Access token is required</p>
      </div>
    </div>
  </div>

  <!--  Form Bulk data-->
  <div class="row col-md-12" *ngIf="accessDetailsForm.controls['environment'].value !== '' && !isHtAccessEnabled">
    <div class="col-md-3" *ngIf="canShow('bulkDataCommunityId')">
      <div class="form-group">
        <label>Bulk Data Community ID<span class="label-required">*</span></label>
        <input id="comm-bukldata-communityId" class="form-control" formControlName="bulkDataCommunityId" type="text"
          placeholder="Bulk Data Community ID">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['bulkDataCommunityId'].hasError('required') && accessDetailsForm.controls['bulkDataCommunityId'].dirty">
          Bulk Data community id is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('bulkDataClientId')">
      <div class="form-group">
        <label>Bulk Data Client ID<span class="label-required">*</span></label>
        <input id="comm-bulkdata-clientID" class="form-control" formControlName="bulkDataClientId" type="text"
          placeholder="bulk Data Client ID">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['bulkDataClientId'].hasError('required') && accessDetailsForm.controls['bulkDataClientId'].dirty">
          Bulk data client ID is required.</p>
      </div>
    </div>
    <div class="col-md-3" *ngIf="canShow('bulkAPIAccessToken')">
      <div class="form-group">
        <label>Bulk API Access Token<span class="label-required">*</span></label>
        <input id="comm-bulkdata-access-token" class="form-control" formControlName="bulkAPIAccessToken" type="text"
          placeholder="Bulk data access token: (Base64 encode)">
        <p class="custom-error"
          *ngIf="accessDetailsForm.controls['bulkAPIAccessToken'].hasError('required') && accessDetailsForm.controls['bulkAPIAccessToken'].dirty">
          Bulk Data Access Token is required</p>
      </div>
    </div>
  </div>
</form>


